import { Tabs } from '@mui/material'
import { Button } from 'components/Button/Button'
import { StyledTab } from 'components/StyledTab/StyledTab'
import TabPanel from 'components/TabPanel/TabPanel'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import Title from 'components/Title/Title'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'

import SchedulerSettings from './SchedulerSettings/SchedulerSettings'
import style from './TaskSettingsModal.module.css'
import { IMissionInfo } from 'utils/interfaces'
import SelectMissionModal from '../../Robot/RobotView/RobotLeftBlock/RobotDetails/RobotMissionUploading/SelectMissionModal/SelectMissionModal'
import SelectRobotModal from '../../Robot/RobotView/RobotLeftBlock/RobotDetails/RobotMissionUploading/SelectRobotModal/SelectRobotModal'
import { MainService, RobotRead, SchedulerSettings as SchedulerSettingsType, MissionJobRead, MissionJobTypeEnum } from 'api/schema'
import { MainApi } from 'api/MainApi'
import { RobotApi } from 'api/RobotApi';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';

import {
  toISOStringWithTimezone,
} from '../TaskSchedulerUtils';

interface IDialogProps {
  isOpen: boolean
  taskName: string
  onClose: (refresh: boolean) => void
  onSubmit: (refresh: boolean) => void
  taskToEdit: MissionJobRead | undefined
}

const TaskSettingsModal = ({ isOpen, taskName, onClose, onSubmit, taskToEdit }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  // const missionInfo = useSelector(selectMissionInfo)

  const [tempTaskName, setTempTaskName] = useState(taskName)
  const [hasNameError, setNameHasError] = useState(false)
  const [showMissionModal, setShowMissionModal] = useState(false)
  const [showRobotModal, setShowRobotModal] = useState(false)
  const [selectedMission, setSelectedMission] = useState<IMissionInfo | undefined>(undefined)
  const [selectedRobot, setSelectedRobot] = useState<RobotRead | undefined>(undefined)
  const [tab, setTab] = useState(0)
  const [date, setDate] = useState<Date | null>(null); 
  const [endDate, setEndDate] = useState<Date | null>(null); 
  const [selectedWeekdays, setSelectedWeekdays] = useState<boolean[]>([false, false, false, false, false, false, false]);
  const isSaveButtonDisabled = tempTaskName === '' || hasNameError ||
                               !selectedMission || !selectedRobot ||
                               !date && !areArraysEqual(selectedWeekdays, [false, false, false, false, false, false, false]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const fetchJobMission = async (id: number) => {
    try {
      const mission = await MainApi.fetchMission(id)
      setSelectedMission(mission.mission)
    } catch (error) {
      setSelectedMission(undefined)
    }
  }

  const fetchJobRobot = async (id: number) => {
    try {
      const robot = await MainService.mainRobotRetrieve(id)
      setSelectedRobot(robot)
    } catch (error) {
      setSelectedRobot(undefined)
    }
  }

  // Prepare data for the edit case
  useEffect(() => {
    if (isOpen)
      setTab(0)

    if (isOpen && taskToEdit){
      fetchJobMission(taskToEdit.mission_id)
      fetchJobRobot(taskToEdit.robot_id)
      setDate(taskToEdit?.scheduler_settings?.datetime? new Date(taskToEdit.scheduler_settings.datetime) : null)
      setSelectedWeekdays(taskToEdit?.scheduler_settings?.weekdays ? [
        taskToEdit.scheduler_settings.weekdays.SU === 'true',
        taskToEdit.scheduler_settings.weekdays.MO === 'true',
        taskToEdit.scheduler_settings.weekdays.TU === 'true',
        taskToEdit.scheduler_settings.weekdays.WE === 'true',
        taskToEdit.scheduler_settings.weekdays.TH === 'true',
        taskToEdit.scheduler_settings.weekdays.FR === 'true',
        taskToEdit.scheduler_settings.weekdays.SA === 'true'
      ] : [false, false, false, false, false, false, false])
    } else { // general case
      setSelectedMission(undefined)
      setSelectedRobot(undefined)
      setDate(null)
      setSelectedWeekdays([false, false, false, false, false, false, false])
    }
    setTempTaskName(taskToEdit?.name? taskToEdit.name : taskName) 
  }, [isOpen]);

  const onTaskNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTempTaskName(value);
    setNameHasError(value === '');
  }

  const handleSchedulerChange = async (newDate: Date | null, newEndDate: Date | null, newSelectedWeekdays: boolean[]) => {
    setDate(newDate);
    setEndDate(newEndDate);
    setSelectedWeekdays(newSelectedWeekdays);
  }

  function areArraysEqual(arr1: boolean[], arr2: boolean[]): boolean {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  }

  const executeSave = async () => {
    let weekdays = null;
    if (selectedMission && selectedRobot) {
      if (!areArraysEqual(selectedWeekdays, ([false, false, false, false, false, false, false]))){
        weekdays = selectedWeekdays ? {        
          SU: selectedWeekdays[0].toString(),
          MO: selectedWeekdays[1].toString(),
          TU: selectedWeekdays[2].toString(),
          WE: selectedWeekdays[3].toString(),
          TH: selectedWeekdays[4].toString(),
          FR: selectedWeekdays[5].toString(),
          SA: selectedWeekdays[6].toString(),
        } : null;
      } 
      const schedulerSettings: SchedulerSettingsType = {
        datetime: date ? toISOStringWithTimezone(date) : null, 
        weekdays,
        except_days : taskToEdit?.scheduler_settings ? taskToEdit.scheduler_settings.except_days : [],
      };
      try {
        if (taskToEdit && taskToEdit?.robot_id === selectedRobot.id){
          // await store.dispatch(robotThunks.updateJobAndUploadJobToRobot(taskToEdit.id, 
          await RobotApi.updateTheEntireJob(taskToEdit.id, 
            selectedMission.id,
            selectedRobot.id,
            schedulerSettings,
            tempTaskName,
          );
        }
        else {  
          // await store.dispatch(robotThunks.createJobAndUploadJobToRobot(selectedMission.id, selectedRobot.id, schedulerSettings, tempTaskName));
          await RobotApi.createJob(selectedMission.id, selectedRobot.id, schedulerSettings, tempTaskName);
          if (taskToEdit && taskToEdit?.robot_id !== selectedRobot.id){
            await RobotApi.updateMissionJobType(taskToEdit.id, MissionJobTypeEnum.DELETE_SCENARIO);
          }
        }
        onSubmit(true)
        resetData()
      } catch (error) {
        console.log("Error saving task: ", error)
      }    
    }
  }

  const onSaveClicked = async () => {
    if (!date)
      setIsConfirmationOpen(true)
    else
      executeSave()
  }

  const handleConfirmSave = () => {
    setIsConfirmationOpen(false)
    executeSave()
  }

  const resetData = () => {
    setSelectedMission(undefined)
    setSelectedRobot(undefined)
    setDate(null)
    setSelectedWeekdays([false, false, false, false, false, false, false])
    setEndDate(null)
    setTempTaskName(taskName)
    setNameHasError(false)
  }

  const onCancelClicked = () => {
    onClose(true)
    resetData()
  }
  const onSelectRobotClick = () => {
    setShowRobotModal(true)
  }

  const onSelectMissionClick = () => {
    setShowMissionModal(true)
  }

  const onMissionSelected = async (mission: IMissionInfo | undefined) => {
    setShowMissionModal(false)
    setSelectedMission(mission)
  }
  const onRobotSelected = async (robot: RobotRead | undefined) => {
    setShowRobotModal(false)
    setSelectedRobot(robot)
  }


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelClicked}
      className={style.root}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(27, 43, 72, 0.6)',
        },
      }}
      ariaHideApp={false}
    >
      <div className={style.modal}>
        <div className={style.head}>
          <Title text={taskToEdit ? "Editing recurring task" : "Adding new task"} className={style.title} />
        </div>
        <Tabs
          value={tab}
          onChange={(e, newValue: number) => {
            setTab(newValue)
          }}
          variant="fullWidth"
          indicatorColor="primary"
          sx={{ height: 45, minHeight: 45 }}
          centered
        >
          <StyledTab
            label="Main"
            sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 185 }}
            disableRipple
          />
          <StyledTab
            label={'Schedule'}
            sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 185 }}
            disableRipple
          />
        </Tabs>
        <TabPanel value={tab} index={0} disablePadding={true}>
          <div className={style.content}>
            <TextFieldDescribed
              className={style.textfieldFullWidth}
              description={"Task name"}
              error={"Invalid task name"}
              placeholder={"Task name"}
              hasError={hasNameError}
              value={tempTaskName}
              onChange={onTaskNameChanged}
            />
            <div className={style.selectBlock}>
              <TextFieldDescribed
                className={style.textfieldFullWidth}
                description={"Robot"}
                placeholder={"Not selected"}
                value={
                  selectedRobot && selectedRobot.serial_number 
                  ? `SN: ${selectedRobot.serial_number}, Name: ${selectedRobot.name}` 
                  : "Not selected"
                }
                disabled
                onChange={() => {}}
              />
              {(
                <Button.Outlined
                  className={style.btnSelect}
                  onClick={onSelectRobotClick}
                >
                  Select Robot
                </Button.Outlined>
              )}
              <SelectRobotModal
                isOpen={showRobotModal}
                onClose={() => setShowRobotModal(false)}
                onConfirmClick={onRobotSelected}
              />
            </div>
            <div className={style.selectBlock}>
              <TextFieldDescribed
                className={style.textfieldFullWidth}
                description={"Mission"}
                placeholder={"Not selected"}
                value={
                  selectedMission && selectedMission.id 
                    ? `ID: ${selectedMission.id}, Name: ${selectedMission.name}` 
                    : "Not selected"
                }
                disabled
                onChange={() => {}}
              />
              {(
                <Button.Outlined
                  className={style.btnSelect}
                  onClick={onSelectMissionClick}
                >
                  Select Mission
                </Button.Outlined>
              )}
              <SelectMissionModal
                isOpen={showMissionModal}
                onClose={() => setShowMissionModal(false)}
                onConfirmClick={onMissionSelected}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tab} index={1} disablePadding={true}>
          <div className={style.content}>
            <SchedulerSettings   
              initialDate={date}
              initialEndDate={endDate}   
              initialWeekdays={selectedWeekdays}
              onSchedulerChange={handleSchedulerChange}/>
          </div>
        </TabPanel>
        <div className={style.footer}>
          <Button.Outlined className={style.btn} onClick={onCancelClicked}>
            {t('mission_settings.label_cancel')}
          </Button.Outlined>
          <Button.Standart
            disabled={isSaveButtonDisabled}
            className={style.btn}
            onClick={onSaveClicked}
          >
            {t('mission_settings.label_save')}
          </Button.Standart>
        </div>
        <ConfirmDialog
          isOpen={isConfirmationOpen}
          onClose={() => setIsConfirmationOpen(false)}
          onConfirmClick={handleConfirmSave}
          title="The task doesn't have a schedule. Execute immediately?"
        />
      </div>
    </Modal>
  )
}

export default TaskSettingsModal
