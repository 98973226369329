import { Button } from 'components/Button/Button'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import Title from 'components/Title/Title'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TextField from '@mui/material/TextField';

import style from './TaskSettingsModal.module.css'
import { IMissionInfo } from 'utils/interfaces'
import SelectRobotModal from '../../Robot/RobotView/RobotLeftBlock/RobotDetails/RobotMissionUploading/SelectRobotModal/SelectRobotModal'
import { MainService, RobotRead, SchedulerSettings as SchedulerSettingsType, MissionJobRead } from 'api/schema'
import { MainApi } from 'api/MainApi'
import { RobotApi } from 'api/RobotApi';

import {
  toISOStringWithTimezone,
  toISOStringYYYYMMDD,
} from '../TaskSchedulerUtils';

interface IDialogProps {
  isOpen: boolean
  taskName: string
  onClose: (refresh: boolean) => void
  onSubmit: (refresh: boolean) => void
  taskToEdit: MissionJobRead | undefined
  dateToEdit: Date | null
}

const SingleTaskEditingModal = ({ isOpen, taskName, onClose, onSubmit, taskToEdit, dateToEdit }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  // const missionInfo = useSelector(selectMissionInfo)

  const [tempTaskName, setTempTaskName] = useState(taskName)
  const [hasNameError, setNameHasError] = useState(false)
  const [showRobotModal, setShowRobotModal] = useState(false)
  const [selectedMission, setSelectedMission] = useState<IMissionInfo | undefined>(undefined)
  const [selectedRobot, setSelectedRobot] = useState<RobotRead | undefined>(undefined)
  const [date, setDate] = useState<Date | null>(null); 
  const [selectedWeekdays, setSelectedWeekdays] = useState<boolean[]>([false, false, false, false, false, false, false]);
  const isSaveButtonDisabled = tempTaskName === '' || selectedMission === undefined || selectedRobot === undefined || hasNameError;

  const fetchJobMission = async (id: number) => {
    try {
      const mission = await MainApi.fetchMission(id)
      setSelectedMission(mission.mission)
    } catch (error) {
      setSelectedMission(undefined)
    }
  }

  const fetchJobRobot = async (id: number) => {
    try {
      const robot = await MainService.mainRobotRetrieve(id)
      setSelectedRobot(robot)
    } catch (error) {
      setSelectedRobot(undefined)
    }
  }

  // Prepare data for the edit case
  useEffect(() => {
    if (isOpen && taskToEdit){
      fetchJobMission(taskToEdit.mission_id)
      fetchJobRobot(taskToEdit.robot_id)
      setDate(dateToEdit)
    } else { // general case
      setSelectedMission(undefined)
      setSelectedRobot(undefined)
      setDate(null)
    }
    setTempTaskName(taskToEdit?.name? taskToEdit.name : taskName) 
  }, [isOpen]);

  const onTaskNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTempTaskName(value);
    setNameHasError(value === '');
  }

  const handleDateChange = (newDate: Date | null) => {
    setDate(newDate);
  };

  const handleClearDate = () => {
    setDate(null);
  };

  const onSaveClicked = async () => {
    if (selectedMission && selectedRobot) {
      const schedulerSettings: SchedulerSettingsType = {
        datetime: date ? toISOStringWithTimezone(date) : null,
        except_days: [],
      };
      try {
        if (taskToEdit && dateToEdit){
          if (taskToEdit.scheduler_settings?.weekdays) {
            console.debug(`Deleting task with id ${taskToEdit.id} and event date ${dateToEdit}`);
            let new_scheduler_settings = { 
              ...taskToEdit.scheduler_settings, 
              except_days: [...taskToEdit.scheduler_settings.except_days]
            };
            new_scheduler_settings.except_days.push(toISOStringYYYYMMDD(dateToEdit));
            new_scheduler_settings.except_days.sort();
            await MainApi.updateMissionJobSchedule(taskToEdit.id, new_scheduler_settings);
            // await store.dispatch(robotThunks.createJobAndUploadJobToRobot(selectedMission.id, selectedRobot.id, schedulerSettings, tempTaskName));
            await RobotApi.createJob(selectedMission.id, selectedRobot.id, schedulerSettings, tempTaskName);
          }
          else {
            // await store.dispatch(robotThunks.updateJobAndUploadJobToRobot(taskToEdit.id, 
            await RobotApi.updateTheEntireJob(taskToEdit.id, 
              selectedMission.id,
              selectedRobot.id,
              schedulerSettings,
              tempTaskName,
            )
            console.debug(`Editing single task with id ${taskToEdit.id} and event date ${dateToEdit}`);
          }
          onSubmit(true);
          resetData();
        }
      } catch (error) {
        console.log("Error editing task: ", error)
      }    
    }
  }
  const resetData = () => {
    setSelectedMission(undefined)
    setSelectedRobot(undefined)
    setDate(null)
    setSelectedWeekdays([false, false, false, false, false, false, false])
    setTempTaskName(taskName)
    setNameHasError(false)
  }

  const onCancelClicked = () => {
    onClose(true)
    resetData()
  }

  const onSelectRobotClick = () => {
    setShowRobotModal(true)
  }

  const onRobotSelected = async (robot: RobotRead | undefined) => {
    setShowRobotModal(false)
    setSelectedRobot(robot)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelClicked}
      className={style.root}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(27, 43, 72, 0.6)',
        },
      }}
      ariaHideApp={false}
    >
      <div className={style.modal}>
        <div className={style.head}>
          <Title text={"Editing current task"} className={style.title} />
        </div>
        <div className={style.content}>
          <TextFieldDescribed
            className={style.textfieldFullWidth}
            description={"Task name"}
            error={"Invalid task name"}
            placeholder={"Task name"}
            hasError={hasNameError}
            value={tempTaskName}
            onChange={onTaskNameChanged}
          />
          <div className={style.selectBlock}>
            <TextFieldDescribed
              className={style.textfieldFullWidth}
              description={"Robot"}
              placeholder={"Not selected"}
              value={
                selectedRobot && selectedRobot.serial_number 
                ? `SN: ${selectedRobot.serial_number}, Name: ${selectedRobot.name}` 
                : "Not selected"
              }
              disabled
              onChange={() => {}}
            />
            {(
              <Button.Outlined
                className={style.btnSelect}
                onClick={onSelectRobotClick}
              >
                Select Robot
              </Button.Outlined>
            )}
            <SelectRobotModal
              isOpen={showRobotModal}
              onClose={() => setShowRobotModal(false)}
              onConfirmClick={onRobotSelected}
            />
          </div>
          <div className={style.selectBlock}>
            <TextFieldDescribed
              className={style.textfieldFullWidth}
              description={"Mission"}
              placeholder={"Not selected"}
              value={
                selectedMission && selectedMission.id 
                  ? `ID: ${selectedMission.id}, Name: ${selectedMission.name}` 
                  : "Not selected"
              }
              disabled
              onChange={() => {}}
            />
          </div>
          <div className={style.singleSettingsDateTimeContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <DateTimePicker
                label="Choose date and time"
                value={date}
                onChange={handleDateChange}
                slots={{ textField: TextField }}
                slotProps={{
                  textField: {
                    sx: {   //  MUI v5: InputProps is not compatible
                      '& .MuiInputBase-root': {
                        height: 40,
                        width: '119%', //TODO: Fix view
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 0,
                      },
                      '& .MuiInputLabel-root': { 
                        fontSize: '16px',
                        textAlign: 'center',
                        position: 'absolute',
                        top: '-15%',
                      },
                      '& .MuiInputLabel-shrink': { 
                        top: '-20%', // Move label to top when active
                        left: '5%', // Align to the left when active
                        transform: 'none',
                        fontSize: '12px'
                      },
                    },
                  },
                }}
              />
              <Button.Outlined className={style.btnClear} onClick={handleClearDate}>
                Clear date and time
              </Button.Outlined>
            </LocalizationProvider>
          </div>
        </div>
        <div className={style.footer}>
          <Button.Outlined className={style.btn} onClick={onCancelClicked}>
            {t('mission_settings.label_cancel')}
          </Button.Outlined>
          <Button.Standart
            disabled={isSaveButtonDisabled}
            className={style.btn}
            onClick={onSaveClicked}
          >
            {t('mission_settings.label_save')}
          </Button.Standart>
        </div>
      </div>
    </Modal>
  )
}

export default SingleTaskEditingModal
