import { MainApi } from 'api/MainApi'
import clsx from 'clsx'
import CommonButton from 'components/Button/ButtonTypes/CommonButton'
import { ReactComponent as CalculateRouteSvg } from 'images/icons/calculate_route.svg'
import { ReactComponent as GearSvg } from 'images/icons/gear.svg'
import { ReactComponent as SuccessSvg } from 'images/icons/success.svg'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import {
  selectMissionCalculatingStatus,
  selectMissionInfo,
  selectMissionReadyForCalculation,
} from 'store/selectors/mission'
import { MISSION_CALCULATING_STATUSES } from 'utils/constants'

import { DrawDropdown } from './DrawDropdown/DrawDropdown'
import MissionSettingsModal from './MissionSettingsModal/MissionSettingsModal'

import style from './MissionButtons.module.css'

const MissionButtons = ({ setCalculationMode }: { setCalculationMode: (mode: 'all' | 'single') => void }) => {
  const { t } = useTranslation()

  const missionInfo = useSelector(selectMissionInfo)
  const calculatingProgress = useSelector(selectMissionCalculatingStatus)
  const readyForCalculation = useSelector(selectMissionReadyForCalculation)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const dispatch = useDispatch()

  const onCalculateRouteClicked = useCallback(async () => {
    if (!readyForCalculation) {
      return
    }
    try {
      await dispatch(MissionActions.setCalculatingStatus(MISSION_CALCULATING_STATUSES.CALCULATING))
      await MainApi.startCalculatingRoutes(missionInfo?.id || -1)
      setCalculationMode('all')
    } catch (error) {
      await dispatch(MissionActions.setCalculatingStatus(MISSION_CALCULATING_STATUSES.ERROR))
    }
  }, [readyForCalculation, dispatch, missionInfo?.id, setCalculationMode])

  const onCloseClicked = useCallback(() => {
    setIsSettingsOpen(false)
  }, [setIsSettingsOpen])

  if (!missionInfo) {
    return null
  }

  return (
    <>
      <div className={style.buttons}>
        <DrawDropdown />
        <CommonButton
          className={clsx(style.button, style.buttonWide)}
          onClick={onCalculateRouteClicked}
          disabled={!readyForCalculation}
        >
          <div className={style.complexButtonContent}>
            {calculatingProgress !== MISSION_CALCULATING_STATUSES.OK && <CalculateRouteSvg />}
            {calculatingProgress === MISSION_CALCULATING_STATUSES.OK && <SuccessSvg />}
            <div className={style.complexButtonDesc}>
              <span className="text-16-500">
                {calculatingProgress === MISSION_CALCULATING_STATUSES.OK
                  ? t('mission.calculate_route_success')
                  : t('mission.calculate_routes')}
              </span>
              <span className="text-12-400">
                {calculatingProgress === MISSION_CALCULATING_STATUSES.OK
                  ? t('mission.calculate_route_success_desc')
                  : t('mission.calculate_route_desc')}
              </span>
            </div>
          </div>
        </CommonButton>
        <CommonButton className={clsx(style.button, style.buttonNarrow)} onClick={() => setIsSettingsOpen(true)}>
          <GearSvg />
        </CommonButton>
      </div>
      <MissionSettingsModal
        isOpen={isSettingsOpen}
        onClose={onCloseClicked}
        missionId={missionInfo.id}
        missionName={missionInfo.name}
      />
    </>
  )
}

export default MissionButtons
