import { RobotApi } from 'api/RobotApi'
import { Button } from 'components/Button/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectRobot } from 'store/selectors/robot'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'
import { ROBOT_COMMANDS, ROBOT_MODES, ROBOT_NETWORK_STATUSES } from 'utils/constants'

import style from './RobotControlButtons.module.css'

const RobotControlButtons = () => {
  const { t } = useTranslation()
  const robot = useSelector(selectRobot)
  const [startButtonBlocked, setStartButtonBlocked] = useState(false)
  // const [stopButtonBlocked, setStopButtonBlocked] = useState(false)
  // const [pauseButtonBlocked, setPauseButtonBlocked] = useState(false)
  const [modeButtonBlocked, setModeButtonBlocked] = useState(false)
  const [disableButtonBlocked, setDisableButtonBlocked] = useState(false)

  const isRobotOffline = robot.robotInfo?.networkStatus === ROBOT_NETWORK_STATUSES.OFFLINE
  const isNotAutomatic = robot.robotInfo?.activeMode !== ROBOT_MODES.MODE_AUTOMATIC
  const isNotSet = robot.robotInfo?.activeMode === ROBOT_MODES.MODE_NOT_SET
  const isStartButtonDisabled =
    robot.robotInfo?.activeMode !== ROBOT_MODES.MODE_AUTOMATIC || robot.robotInfo?.activeCommand === ROBOT_COMMANDS.START 

  // const stopButtonDisabled =
  //   robot.robotInfo?.activeMode !== ROBOT_MODES.MODE_AUTOMATIC || robot.robotInfo?.activeCommand === ROBOT_COMMANDS.STOP
  // const pauseButtonDisabled =
  //   robot.robotInfo?.activeMode !== ROBOT_MODES.MODE_AUTOMATIC || robot.robotInfo?.activeCommand !== ROBOT_COMMANDS.START
  // const setAutomaticButtonDisabled = robot.robotInfo?.activeMode === ROBOT_MODES.MODE_AUTOMATIC || 
  //                                    robot.robotInfo?.activeMode === ROBOT_MODES.MODE_WASHING ||
  //                                    robot.robotInfo?.activeMode === ROBOT_MODES.MODE_MAPPING ||
  //                                    robot.robotInfo?.activeMode === ROBOT_MODES.MODE_ROUTE_RECORDING
  // const disableButtonDisabled = robot.robotInfo?.activeMode === ROBOT_MODES.MODE_NOT_SET || 
  //                               robot.robotInfo?.activeMode === ROBOT_MODES.MODE_WASHING ||
  //                               robot.robotInfo?.activeMode === ROBOT_MODES.MODE_MAPPING ||
  //                               robot.robotInfo?.activeMode === ROBOT_MODES.MODE_ROUTE_RECORDING

  // const onStartClicked = async () => {
  //   if (robot.robotInfo?.id) {
  //     try {
  //       setStartButtonBlocked(true)
  //       await RobotApi.execCommand(robot.robotInfo?.id, ROBOT_COMMANDS.START)
  //       setTimeout(() => {
  //         setStartButtonBlocked(false)
  //       }, 4500)
  //     } catch (error) {
  //       setStartButtonBlocked(false)
  //     }
  //   }
  // }

  // const onStopClicked = async () => {
  //   if (robot.robotInfo?.id) {
  //     try {
  //       setStopButtonBlocked(true)
  //       await RobotApi.execCommand(robot.robotInfo?.id, ROBOT_COMMANDS.STOP)
  //       setTimeout(() => {
  //         setStopButtonBlocked(false)
  //       }, 4500)
  //     } catch (error) {
  //       setStopButtonBlocked(false)
  //     }
  //   }
  // }

  // const onPauseClicked = async () => {
  //   if (robot.robotInfo?.id) {
  //     try {
  //       setPauseButtonBlocked(true)
  //       await RobotApi.execCommand(robot.robotInfo?.id, ROBOT_COMMANDS.PAUSE)
  //       setTimeout(() => {
  //         setPauseButtonBlocked(false)
  //       }, 4500)
  //     } catch (error) {
  //       setPauseButtonBlocked(false)
  //     }
  //   }
  // }

  const onNotSetModeClicked = () => {
    if (robot.robotInfo?.id !== undefined) {
      setDisableButtonBlocked(true)
      store.dispatch(robotThunks.setRobotNotSetMode(robot.robotInfo?.id))
      setTimeout(() => {
        setDisableButtonBlocked(false)
      }, 4500)
    } 
  }

  // const onSetAutomaticModeClicked = () => {
  //   if (robot.robotInfo?.id !== undefined) {
  //     setModeButtonBlocked(true)
  //     store.dispatch(robotThunks.setRobotToAutomaticMode(robot.robotInfo?.id))
  //     setTimeout(() => {
  //       setModeButtonBlocked(false)
  //     }, 4500)
  //   }
  // }

  const onStartPauseClicked = async () => {
    if (robot.robotInfo?.id) {
      try {
        setStartButtonBlocked(true)
        if (robot.robotInfo?.activeCommand === ROBOT_COMMANDS.START)
          await RobotApi.execCommand(robot.robotInfo?.id, ROBOT_COMMANDS.PAUSE)
        else
          await RobotApi.execCommand(robot.robotInfo?.id, ROBOT_COMMANDS.START)
        setTimeout(() => {
          setStartButtonBlocked(false)
        }, 4500)
      } catch (error) {
        setStartButtonBlocked(false)
      }
    }
  }

  return (
    <>
      <div className={style.row}>
        <Button.Standart
          className={style.button}
          disabled={isRobotOffline || isNotAutomatic}
          onClick={onStartPauseClicked}
          loading={modeButtonBlocked}
        >
          {isStartButtonDisabled ? t('mission_control.pause') : t('mission_control.start')}
          </Button.Standart>
        <Button.Standart
          className={style.button}
          disabled={isRobotOffline || isNotSet}
          onClick={onNotSetModeClicked}
          loading={disableButtonBlocked}
        >
          {t('mission_control.disable')}
        </Button.Standart>
      </div>
      {/* <div className={style.root}>
        <Button.Standart
          className={style.button}
          disabled={startButtonDisabled || startButtonBlocked}
          onClick={onStartClicked}
          loading={startButtonBlocked}
        >
          {t('mission_control.start')}
        </Button.Standart>
        <Button.Standart
          className={style.button}
          disabled={stopButtonDisabled || stopButtonBlocked}
          onClick={onStopClicked}
          loading={stopButtonBlocked}
        >
          {t('mission_control.stop')}
        </Button.Standart>
        <Button.Outlined
          className={style.buttonStop}
          disabled={pauseButtonDisabled || pauseButtonBlocked}
          onClick={onPauseClicked}
          loading={pauseButtonBlocked}
        >
          {t('mission_control.pause')}
        </Button.Outlined>
      </div> */}
    </>
  )
}

export default RobotControlButtons
