/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
import type { AttachGeoTiffToMissionRequest } from '../models/AttachGeoTiffToMissionRequest'
import type { CleaningZone } from '../models/CleaningZone'
import type { CleaningZoneRead } from '../models/CleaningZoneRead'
import type { CleaningZoneRequest } from '../models/CleaningZoneRequest'
import type { GeoTiffFile } from '../models/GeoTiffFile';
import type { Mission } from '../models/Mission'
import type { MissionJob } from '../models/MissionJob'
import type { MissionJobRead } from '../models/MissionJobRead'
import type { MissionJobRequest } from '../models/MissionJobRequest'
import type { MissionLayerUploadFileRequest } from '../models/MissionLayerUploadFileRequest'
import type { MissionMapLayer } from '../models/MissionMapLayer'
import type { MissionMapLayerRead } from '../models/MissionMapLayerRead'
import type { MissionMapLayerRequest } from '../models/MissionMapLayerRequest'
import type { MissionPointTransferRoute } from '../models/MissionPointTransferRoute'
import type { MissionPointTransferRouteRead } from '../models/MissionPointTransferRouteRead'
import type { MissionPointTransferRouteRequest } from '../models/MissionPointTransferRouteRequest'
import type { MissionRead } from '../models/MissionRead'
import type { MissionRequest } from '../models/MissionRequest'
import type { MissionStatus } from '../models/MissionStatus'
import type { MissionXyzLayer } from '../models/MissionXyzLayer'
import type { MissionXyzLayerRead } from '../models/MissionXyzLayerRead'
import type { MissionXyzLayerRequest } from '../models/MissionXyzLayerRequest'
import type { PaginatedMissionJobReadList } from '../models/PaginatedMissionJobReadList'
import type { PaginatedMissionMapLayerReadList } from '../models/PaginatedMissionMapLayerReadList'
import type { PaginatedMissionPointTransferRouteReadList } from '../models/PaginatedMissionPointTransferRouteReadList'
import type { PaginatedMissionReadListList } from '../models/PaginatedMissionReadListList'
import type { PaginatedMissionXyzLayerReadList } from '../models/PaginatedMissionXyzLayerReadList'
import type { PaginatedRestrictZoneReadList } from '../models/PaginatedRestrictZoneReadList'
import type { PaginatedRobotGarageReadList } from '../models/PaginatedRobotGarageReadList'
import type { PaginatedRobotReadList } from '../models/PaginatedRobotReadList'
import type { PaginatedTransferRouteReadList } from '../models/PaginatedTransferRouteReadList'
import type { PatchedCleaningZoneRequest } from '../models/PatchedCleaningZoneRequest'
import type { PatchedMissionMapLayerRequest } from '../models/PatchedMissionMapLayerRequest'
import type { PatchedMissionPointTransferRouteRequest } from '../models/PatchedMissionPointTransferRouteRequest'
import type { PatchedMissionRequest } from '../models/PatchedMissionRequest'
import type { PatchedMissionJobRequest } from '../models/PatchedMissionJobRequest'
import type { PatchedMissionXyzLayerRequest } from '../models/PatchedMissionXyzLayerRequest'
import type { PatchedRestrictZoneRequest } from '../models/PatchedRestrictZoneRequest'
import type { PatchedRobotGarageRequest } from '../models/PatchedRobotGarageRequest'
import type { PatchedRobotRequest } from '../models/PatchedRobotRequest'
import type { PatchedTransferRouteRequest } from '../models/PatchedTransferRouteRequest'
import type { SchedulerSettings } from '../models/SchedulerSettings'
import type { RestrictZone } from '../models/RestrictZone'
import type { RestrictZoneRead } from '../models/RestrictZoneRead'
import type { RestrictZoneRequest } from '../models/RestrictZoneRequest'
import type { Robot } from '../models/Robot'
import type { RobotGarage } from '../models/RobotGarage'
import type { RobotGarageRead } from '../models/RobotGarageRead'
import type { RobotGarageRequest } from '../models/RobotGarageRequest'
import type { RobotRead } from '../models/RobotRead'
import type { RobotRequest } from '../models/RobotRequest'
import type { TransferRoute } from '../models/TransferRoute'
import type { TransferRouteRead } from '../models/TransferRouteRead'
import type { TransferRouteRequest } from '../models/TransferRouteRequest'
import { EventStatisticsResponse } from '../models/EventStatistics'

export class MainService {
  /**
   * @param missionId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedMissionReadListList
   * @throws ApiError
   */
  public static mainCleaningZoneList(
    missionId?: Array<number>,
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedMissionReadListList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/cleaning-zone/',
      query: {
        mission_id: missionId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns CleaningZone
   * @throws ApiError
   */
  public static mainCleaningZoneCreate(requestBody: CleaningZoneRequest): CancelablePromise<CleaningZone> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/cleaning-zone/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this cleaning zone.
   * @returns CleaningZoneRead
   * @throws ApiError
   */
  public static mainCleaningZoneRetrieve(id: number): CancelablePromise<CleaningZoneRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/cleaning-zone/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this cleaning zone.
   * @param requestBody
   * @returns CleaningZone
   * @throws ApiError
   */
  public static mainCleaningZonePartialUpdate(
    id: number,
    requestBody?: PatchedCleaningZoneRequest
  ): CancelablePromise<CleaningZone> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/cleaning-zone/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this cleaning zone.
   * @returns void
   * @throws ApiError
   */
  public static mainCleaningZoneDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/cleaning-zone/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this cleaning zone.
   * @returns any No response body
   * @throws ApiError
   */
  public static mainCleaningZoneStartCalculationCreate(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/cleaning-zone/{id}/start-calculation/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedMissionReadListList
   * @throws ApiError
   */
  public static mainMissionList(
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedMissionReadListList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission/',
      query: {
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns Mission
   * @throws ApiError
   */
  public static mainMissionCreate(requestBody?: MissionRequest): CancelablePromise<Mission> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param missionId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param robotId
   * @param search A search term.
   * @param status
   * @returns PaginatedMissionJobReadList
   * @throws ApiError
   */
  public static mainMissionJobList(
    missionId?: Array<number>,
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    robotId?: Array<number>,
    search?: string,
    status?: 'new' | 'scenario_calculating' | 'scenario_done' | 'scenario_error',
    scheduler_settings?: SchedulerSettings
  ): CancelablePromise<PaginatedMissionJobReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-job/',
      query: {
        mission_id: missionId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        robot_id: robotId,
        search: search,
        status: status,
        scheduler_settings: scheduler_settings,
      },
    })
  }

  /**
   * @param requestBody
   * @returns MissionJob
   * @throws ApiError
   */
  public static mainMissionJobCreate(requestBody: MissionJobRequest): CancelablePromise<MissionJob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-job/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission job.
   * @returns MissionJobRead
   * @throws ApiError
   */
  public static mainMissionJobRetrieve(id: number): CancelablePromise<MissionJobRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-job/{id}/',
      path: {
        id: id,
      },
    })
  }

    /**
   * @param id A unique integer value identifying this mission job.
   * @returns void
   * @throws ApiError
   */
    public static mainMissionJobDestroy(id: number): CancelablePromise<void> {
      return __request(OpenAPI, {
        method: 'DELETE',
        url: '/api/main/mission-job/{id}/',
        path: {
          id: id,
        },
      })
    }

  /**
   * @param id A unique integer value identifying this mission.
   * @param requestBody
   * @returns Mission
   * @throws ApiError
   */
  public static mainMissionJobPartialUpdate(id: number, requestBody?: PatchedMissionJobRequest): CancelablePromise<MissionJob> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/mission-job/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission job.
   * @returns any No response body
   * @throws ApiError
   */
  public static mainMissionJobStartCalculationCreate(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-job/{id}/start-calculation/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @returns GeoTiffFile[]
   * @throws ApiError
   */
  public static mainListGeoTiffFiles(): CancelablePromise<Array<GeoTiffFile>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/geotiff-files/',
    });
  }

  /**
    * Uploads a GeoTIFF to a specified layer by filename.
    * @param layerId The ID of the existing layer.
    * @param requestBody Contains the filename of the GeoTIFF.
    * @returns any
    * @throws ApiError
    */
  public static mainMissionXyzLayerUploadFileByName(
    layerId: number,
    requestBody: AttachGeoTiffToMissionRequest
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `/api/main/mission-xyz-layer/{layer_id}/upload-file-by-name/`, 
      path: {
          layer_id: layerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }


  /**
   * @param missionId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedMissionMapLayerReadList
   * @throws ApiError
   */
  public static mainMissionMapLayerList(
    missionId?: Array<number>,
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedMissionMapLayerReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-map-layer/',
      query: {
        mission_id: missionId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns MissionMapLayer
   * @throws ApiError
   */
  public static mainMissionMapLayerCreate(requestBody: MissionMapLayerRequest): CancelablePromise<MissionMapLayer> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-map-layer/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission map.
   * @returns MissionMapLayerRead
   * @throws ApiError
   */
  public static mainMissionMapLayerRetrieve(id: number): CancelablePromise<MissionMapLayerRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-map-layer/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission map.
   * @param requestBody
   * @returns MissionMapLayer
   * @throws ApiError
   */
  public static mainMissionMapLayerPartialUpdate(
    id: number,
    requestBody?: PatchedMissionMapLayerRequest
  ): CancelablePromise<MissionMapLayer> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/mission-map-layer/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission map.
   * @returns void
   * @throws ApiError
   */
  public static mainMissionMapLayerDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/mission-map-layer/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission map.
   * @param formData
   * @returns MissionMapLayerRead
   * @throws ApiError
   */
  public static mainMissionMapLayerUploadFileCreate(
    id: number,
    formData: MissionLayerUploadFileRequest
  ): CancelablePromise<MissionMapLayerRead> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-map-layer/{id}/upload-file/',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    })
  }

  /**
   * @param cleaningZone
   * @param missionId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedMissionPointTransferRouteReadList
   * @throws ApiError
   */
  public static mainMissionPointTransferRouteList(
    cleaningZone?: Array<number>,
    missionId?: Array<number>,
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedMissionPointTransferRouteReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-point-transfer-route/',
      query: {
        cleaning_zone: cleaningZone,
        mission_id: missionId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns MissionPointTransferRoute
   * @throws ApiError
   */
  public static mainMissionPointTransferRouteCreate(
    requestBody: MissionPointTransferRouteRequest
  ): CancelablePromise<MissionPointTransferRoute> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-point-transfer-route/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission point transfer route.
   * @returns MissionPointTransferRouteRead
   * @throws ApiError
   */
  public static mainMissionPointTransferRouteRetrieve(id: number): CancelablePromise<MissionPointTransferRouteRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-point-transfer-route/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission point transfer route.
   * @param requestBody
   * @returns MissionPointTransferRoute
   * @throws ApiError
   */
  public static mainMissionPointTransferRoutePartialUpdate(
    id: number,
    requestBody?: PatchedMissionPointTransferRouteRequest
  ): CancelablePromise<MissionPointTransferRoute> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/mission-point-transfer-route/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission point transfer route.
   * @returns void
   * @throws ApiError
   */
  public static mainMissionPointTransferRouteDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/mission-point-transfer-route/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission point transfer route.
   * @returns any No response body
   * @throws ApiError
   */
  public static mainMissionPointTransferRouteStartCalculationCreate(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-point-transfer-route/{id}/start-calculation/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param missionId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedMissionXyzLayerReadList
   * @throws ApiError
   */
  public static mainMissionXyzLayerList(
    missionId?: Array<number>,
    ordering?: Array<
      | 'id'
      | '-id'
      | 'name'
      | '-name'
      | 'created_at'
      | '-created_at'
      | 'updated_at'
      | '-updated_at'
      | 'sorting'
      | '-sorting'
    >,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedMissionXyzLayerReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-xyz-layer/',
      query: {
        mission_id: missionId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns MissionXyzLayer
   * @throws ApiError
   */
  public static mainMissionXyzLayerCreate(requestBody: MissionXyzLayerRequest): CancelablePromise<MissionXyzLayer> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-xyz-layer/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission XYZ layer.
   * @returns MissionXyzLayerRead
   * @throws ApiError
   */
  public static mainMissionXyzLayerRetrieve(id: number): CancelablePromise<MissionXyzLayerRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission-xyz-layer/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission XYZ layer.
   * @param requestBody
   * @returns MissionXyzLayer
   * @throws ApiError
   */
  public static mainMissionXyzLayerPartialUpdate(
    id: number,
    requestBody?: PatchedMissionXyzLayerRequest
  ): CancelablePromise<MissionXyzLayer> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/mission-xyz-layer/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission XYZ layer.
   * @returns void
   * @throws ApiError
   */
  public static mainMissionXyzLayerDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/mission-xyz-layer/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission XYZ layer.
   * @param formData
   * @returns MissionXyzLayerRead
   * @throws ApiError
   */
  public static mainMissionXyzLayerUploadFileCreate(
    id: number,
    formData: MissionLayerUploadFileRequest
  ): CancelablePromise<MissionXyzLayerRead> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission-xyz-layer/{id}/upload-file/',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    })
  }

  /**
   * @param id A unique integer value identifying this mission.
   * @returns MissionRead
   * @throws ApiError
   */
  public static mainMissionRetrieve(id: number): CancelablePromise<MissionRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission.
   * @param requestBody
   * @returns Mission
   * @throws ApiError
   */
  public static mainMissionPartialUpdate(id: number, requestBody?: PatchedMissionRequest): CancelablePromise<Mission> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/mission/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this mission.
   * @returns void
   * @throws ApiError
   */
  public static mainMissionDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/mission/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission.
   * @returns void
   * @throws ApiError
   */
  public static mainMissionCopy(id: number): CancelablePromise<Mission> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission/{id}/copy/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this mission.
   * @returns MissionStatus
   * @throws ApiError
   */
  public static mainMissionGetStatusRetrieve(id: number): CancelablePromise<MissionStatus> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/mission/{id}/get-status/',
      path: {
        id: id,
      },
    })
  }

  /**
   * Чтобы принудительно перерасчитать маршруты для всего надо дослать `?force=1`
   * @param id A unique integer value identifying this mission.
   * @returns any No response body
   * @throws ApiError
   */
  public static mainMissionStartCalculationCreate(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/mission/{id}/start-calculation/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param missionId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedRestrictZoneReadList
   * @throws ApiError
   */
  public static mainRestrictZoneList(
    missionId?: Array<number>,
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedRestrictZoneReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/restrict-zone/',
      query: {
        mission_id: missionId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns RestrictZone
   * @throws ApiError
   */
  public static mainRestrictZoneCreate(requestBody: RestrictZoneRequest): CancelablePromise<RestrictZone> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/restrict-zone/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this restrict zone.
   * @returns RestrictZoneRead
   * @throws ApiError
   */
  public static mainRestrictZoneRetrieve(id: number): CancelablePromise<RestrictZoneRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/restrict-zone/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this restrict zone.
   * @param requestBody
   * @returns RestrictZone
   * @throws ApiError
   */
  public static mainRestrictZonePartialUpdate(
    id: number,
    requestBody?: PatchedRestrictZoneRequest
  ): CancelablePromise<RestrictZone> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/restrict-zone/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this restrict zone.
   * @returns void
   * @throws ApiError
   */
  public static mainRestrictZoneDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/restrict-zone/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedRobotReadList
   * @throws ApiError
   */
  public static mainRobotList(
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedRobotReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/robot/',
      query: {
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns Robot
   * @throws ApiError
   */
  public static mainRobotCreate(requestBody: RobotRequest): CancelablePromise<Robot> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/robot/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedRobotGarageReadList
   * @throws ApiError
   */
  public static mainRobotGarageList(
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedRobotGarageReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/robot-garage/',
      query: {
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns RobotGarage
   * @throws ApiError
   */
  public static mainRobotGarageCreate(requestBody?: RobotGarageRequest): CancelablePromise<RobotGarage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/robot-garage/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this robot garage.
   * @returns RobotGarageRead
   * @throws ApiError
   */
  public static mainRobotGarageRetrieve(id: number): CancelablePromise<RobotGarageRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/robot-garage/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this robot garage.
   * @param requestBody
   * @returns RobotGarage
   * @throws ApiError
   */
  public static mainRobotGaragePartialUpdate(
    id: number,
    requestBody?: PatchedRobotGarageRequest
  ): CancelablePromise<RobotGarage> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/robot-garage/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this robot garage.
   * @returns void
   * @throws ApiError
   */
  public static mainRobotGarageDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/robot-garage/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this robot.
   * @returns RobotRead
   * @throws ApiError
   */
  public static mainRobotRetrieve(id: number): CancelablePromise<RobotRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/robot/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this robot.
   * @param requestBody
   * @returns Robot
   * @throws ApiError
   */
  public static mainRobotPartialUpdate(id: number, requestBody?: PatchedRobotRequest): CancelablePromise<Robot> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/robot/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this robot.
   * @returns void
   * @throws ApiError
   */
  public static mainRobotDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/robot/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param cmd
   * @param id A unique integer value identifying this robot.
   * @returns any No response body
   * @throws ApiError
   */
  public static mainRobotExecCommandCreate(id: number, cmd: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/robot/{id}/exec-command/{cmd}/',
      path: {
        cmd: cmd,
        id: id,
      },
    })
  }

  /**
   * @param cmd
   * @param id A unique integer value identifying this robot.
   * @param value
   * @returns any No response body
   * @throws ApiError
   */
  public static mainRobotExecEquipmentCommandCreate(cmd: string, id: number, value: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/robot/{id}/exec-equipment-command/{cmd}/{value}/',
      path: {
        cmd: cmd,
        id: id,
        value: value,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this robot.
   * @param mode
   * @returns any No response body
   * @throws ApiError
   */
  public static mainRobotSwitchModeCreate(id: number, mode: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/robot/{id}/switch-mode/{mode}/',
      path: {
        id: id,
        mode: mode,
      },
    })
  }

  /**
   * @returns any No response body
   * @throws ApiError
   */
  public static mainTestRetrieve(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/test/',
    })
  }

  /**
   * @returns any No response body
   * @throws ApiError
   */
  public static mainTest2Retrieve(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/test2/',
    })
  }

  /**
   * @returns any No response body
   * @throws ApiError
   */
  public static mainTest3Retrieve(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/test3/',
    })
  }

  /**
   * @param cleaningZoneFromId
   * @param cleaningZoneToId
   * @param missionId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedTransferRouteReadList
   * @throws ApiError
   */
  public static mainTransferRouteList(
    cleaningZoneFromId?: Array<number>,
    cleaningZoneToId?: Array<number>,
    missionId?: Array<string>,
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedTransferRouteReadList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/transfer-route/',
      query: {
        cleaning_zone_from_id: cleaningZoneFromId,
        cleaning_zone_to_id: cleaningZoneToId,
        mission_id: missionId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param requestBody
   * @returns TransferRoute
   * @throws ApiError
   */
  public static mainTransferRouteCreate(requestBody?: TransferRouteRequest): CancelablePromise<TransferRoute> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/transfer-route/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this transfer route.
   * @returns TransferRouteRead
   * @throws ApiError
   */
  public static mainTransferRouteRetrieve(id: number): CancelablePromise<TransferRouteRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/transfer-route/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this transfer route.
   * @param requestBody
   * @returns TransferRoute
   * @throws ApiError
   */
  public static mainTransferRoutePartialUpdate(
    id: number,
    requestBody?: PatchedTransferRouteRequest
  ): CancelablePromise<TransferRoute> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/transfer-route/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this transfer route.
   * @returns void
   * @throws ApiError
   */
  public static mainTransferRouteDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/transfer-route/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this transfer route.
   * @returns any No response body
   * @throws ApiError
   */
  public static mainTransferRouteStartCalculationCreate(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/transfer-route/{id}/start-calculation/',
      path: {
        id: id,
      },
    })
  }

  /**
   * Get event statistics with optional filters
   * @param params Query parameters for filtering
   * @returns EventStatistics[]
   * @throws ApiError
   */
  public static getEventStatistics(params?: {
    start_date?: string;
    end_date?: string;
    robot_sn?: number;
    mission_id?: number;
    task_id?: number;
    page?: number;
    page_size?: number;
    ordering?: string;
  }): CancelablePromise<EventStatisticsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/event-statistics/',
      query: params,
    });
  }

  /**
   * Get event statistics for specific mission
   * @param missionId Mission ID
   * @returns EventStatistics[]
   * @throws ApiError
   */
  public static getMissionEventStatistics(missionId: number): CancelablePromise<EventStatisticsResponse> {
    return this.getEventStatistics({ mission_id: missionId });
  }

  /**
   * Get event statistics for date range
   * @param startDate Start date in ISO format
   * @param endDate End date in ISO format
   * @returns EventStatistics[]
   * @throws ApiError
   */
  public static getEventStatisticsByDateRange(startDate: string, endDate: string): CancelablePromise<EventStatisticsResponse> {
    return this.getEventStatistics({
      start_date: startDate,
      end_date: endDate,
    });
  }
}
