import clsx from 'clsx'
import { ReactComponent as LoaderSvg } from 'images/icons/loader.svg'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import style from './CalculatingLoader.module.css'

const CalculatingLoader = (props: { visible: boolean, ready: [number, number, number, number], mode: 'all' | 'single' | undefined }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={props.visible}
      shouldCloseOnOverlayClick={false}
      style={{ overlay: { zIndex: 100, backgroundColor: 'rgba(27, 43, 72, 0.6)' } }}
      ariaHideApp={false}
      className={clsx(style.root)}
      shouldCloseOnEsc={false}
    >
      <div className={style.content}>
        <LoaderSvg className={style.loader} />
        <div>
          <div className="text-16-500">{t('mission.calculate_route_in_progress')}</div>
          <div className={clsx(style.desc, 'text-12-400')}>{t('mission.calculate_route_in_progress_desc')}</div>
          {props.mode === 'all' ? (
            <>
              <div className="text-12-400">{`Cleaning routes ready: ${props.ready[1]} / ${props.ready[0]}`}</div>
              <div className="text-12-400">{`Transfer routes ready: ${props.ready[3]} / ${props.ready[2]}`}</div>
            </>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CalculatingLoader
