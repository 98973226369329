import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { ReactComponent as MissionSvg } from 'images/icons/mission_play.svg'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectRobot } from 'store/selectors/robot'
import { generatePath, useNavigate } from 'react-router-dom'
import { selectRobotActiveMode, selectRobotJob, selectRobotJobUploadingProgress } from 'store/selectors/robot'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'
import { MISSION_JOB_STATUSES, PATH, PROGRESS_TYPES, ROBOT_MODES } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'
import { RobotApi } from 'api/RobotApi'

import SelectMissionModal from './SelectMissionModal/SelectMissionModal'

import style from './RobotMissionUploading.module.css'

enum STATES {
  SELECT_MISSION,
  MISSION_READY_TO_START,
  UPLOADING_MISSION_ERROR,
  UPLOADING,
  CALCULATING_SCENARIO,
  TURN_ON_ROBOT,
  MISSION_IS_ACTIVE,
  MISSION_IS_NOT_ACTIVE,
}

const RobotMissionUploading = ({ robotId }: { robotId?: number }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const uploadingState = useSelector(selectRobotJobUploadingProgress)
  const job = useSelector(selectRobotJob)
  const robot = useSelector(selectRobot)
  const [showModal, setShowModal] = useState(false)
  const [selectedMission, setSelectedMission] = useState<IMissionInfo | undefined>(undefined)
  const activeMode = useSelector(selectRobotActiveMode)

  const state = useMemo(() => {
    if (activeMode === null || activeMode === undefined) {
      return STATES.TURN_ON_ROBOT
    }

    if (activeMode !== ROBOT_MODES.MODE_NOT_SET) {
      return STATES.MISSION_IS_ACTIVE
    }

    if (activeMode === ROBOT_MODES.MODE_NOT_SET) {
      return STATES.MISSION_IS_NOT_ACTIVE
    }

    return STATES.TURN_ON_ROBOT

  }, [activeMode])

  const styles = {
    [`${STATES.TURN_ON_ROBOT}`]: style.idle,
    [`${STATES.MISSION_IS_ACTIVE}`]: style.idle,
    [`${STATES.MISSION_IS_NOT_ACTIVE}`]: style.idle,
  }

  const messages = {
    [STATES.TURN_ON_ROBOT]: t('robot.turn_on'),
    [STATES.MISSION_IS_ACTIVE]: t('robot.active'),
    [STATES.MISSION_IS_NOT_ACTIVE]: t('robot.not_active'),
  }

  const missionInProgress = activeMode !== ROBOT_MODES.MODE_NOT_SET
  const missionInAutomatic = activeMode == ROBOT_MODES.MODE_AUTOMATIC
  const missionInParking = activeMode == ROBOT_MODES.MODE_PARKING
  const missionInCharging = activeMode == ROBOT_MODES.MODE_CHARGING
  const missionInWashing = activeMode == ROBOT_MODES.MODE_WASHING
  const missionInMapping = activeMode == ROBOT_MODES.MODE_MAPPING || activeMode == ROBOT_MODES.MODE_ROUTE_RECORDING

  // const onSelectClick = () => {
  //   setShowModal(true)
  // }

  const onGoToMissionClick = () => {
    if (job) {
      const path = generatePath(PATH.MISSION_CONTROL, { id: String(job.id) })
      navigate(path)
    }
  }

  const onRetryClick = async () => {
    if (selectedMission && robotId) {
      // store.dispatch(robotThunks.createJobAndUploadJobToRobot(selectedMission.id, robotId))
      await RobotApi.createJob(selectedMission.id, robotId)
    }
  }

  const onMissionSelected = async (mission: IMissionInfo | undefined) => {
    setShowModal(false)
    setSelectedMission(mission)
    if (mission && robotId) {
      // store.dispatch(robotThunks.createJobAndUploadJobToRobot(mission.id, robotId))
      await RobotApi.createJob(mission.id, robotId)
    }
  }

  const onSetParkingModeClicked = () => {
    if (robot.robotInfo?.id !== undefined) {
      if (!missionInParking)
        store.dispatch(robotThunks.setRobotToParkingMode(robot.robotInfo?.id))
      else
        store.dispatch(robotThunks.setRobotNotSetMode(robot.robotInfo?.id))
    }
  }

  const onSetChargingModeClicked = () => {
    if (robot.robotInfo?.id !== undefined) {
      if (!missionInCharging)
        store.dispatch(robotThunks.setRobotToChargingMode(robot.robotInfo?.id))
      else
        store.dispatch(robotThunks.setRobotNotSetMode(robot.robotInfo?.id))
    }
  }

  const onSetWashingModeClicked = () => {
    if (robot.robotInfo?.id !== undefined) {
      if (!missionInWashing)
        store.dispatch(robotThunks.setRobotToWashingMode(robot.robotInfo?.id))
      else
        store.dispatch(robotThunks.setRobotNotSetMode(robot.robotInfo?.id))
    }
  }


  return (
    <div>
      <div className={clsx(style.main, styles[state])}>
        <MissionSvg />
        <div className={style.message} dangerouslySetInnerHTML={{ __html: messages[state] }} />
        {uploadingState === PROGRESS_TYPES.ERROR && (
          <div className={style.retryText}>
            <span className={style.link} onClick={onRetryClick}>
              retry
            </span>{' '}
            or
          </div>
        )}
        {(
          <Button.Standart className={style.buttonWide} onClick={onGoToMissionClick} disabled={missionInWashing || missionInMapping}>
            {t('robot.button_go_to_mission')}
          </Button.Standart>
        )}
        {/* {!isUploading && (
          <Button.Standart className={style.button} onClick={onSelectClick} disabled={missionInAutomatic || missionInMapping}>
            {t('robot.button_mission_select')}
          </Button.Standart>
        )}

        {isUploading && (
          <Button.Standart className={style.button} disabled={true} loading={true}>
            {t('robot.button_mission_uploading')}
          </Button.Standart>
        )}
        <SelectMissionModal
          selectedMissionId={job?.missionId}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirmClick={onMissionSelected}
        /> */}
      </div>
      <div className={clsx(style.main, styles[state])}>
        <div className={style.text} dangerouslySetInnerHTML={{ __html: 'Maintenance tasks' }} />
        {(
          <Button.Standart
            className={style.button}
            disabled={missionInProgress && !missionInParking}
            onClick={onSetParkingModeClicked}
          >
            {!missionInParking ? 'Parking' : 'Stop Parking'}
          </Button.Standart>
        )}
        {(
          <Button.Standart
            className={style.button}
            disabled={missionInProgress && !missionInCharging}
            onClick={onSetChargingModeClicked}
          >
            {!missionInCharging ? 'Charging' : 'Stop Charging'}
          </Button.Standart>
        )}
        {(
          <Button.Standart
            className={style.button}
            disabled={missionInProgress && !missionInWashing}
            onClick={onSetWashingModeClicked}
          >
            {!missionInWashing ? 'Washing' : 'Stop Washing'}
          </Button.Standart>
        )}
      </div>
    </div>
  )
}

export default RobotMissionUploading
