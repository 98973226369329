import React from 'react';
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectRobot} from 'store/selectors/robot'
import { RobotReadMissionJobInline, MissionJobTypeEnum, MissionJobRead } from 'api/schema';
import { PATH } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import style from './RobotTasks.module.css'
import TaskSettingsModal from '../../../../TaskScheduler/TaskSettingsModal/TaskSettingsModal';
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'

const RobotTasks = () => {
  const robot = useSelector(selectRobot)
  const [tasks, setTasks] = React.useState<RobotReadMissionJobInline[]>([])
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedTask, setSelectedTask] = React.useState<MissionJobRead | null>(null)
  
  useEffect(() => {
    const fetchTasks = async () => {
      if (robot.robotInfo?.robotRaw?.missions_jobs) {
        setTasks(robot.robotInfo.robotRaw.missions_jobs)
      }
      else {
        setTasks([])
      }
    }
    fetchTasks()
  }, [robot])

  const getLoadingStatus = (task: RobotReadMissionJobInline) => {
    let loadingStatus = '';
    if (task.status) {
      loadingStatus = task.status === 'new' || task.status === 'scenario_error' ? 'Not loaded' : task.status === 'scenario_done' ? 'Loaded' : 'Loading';
    }
    return loadingStatus;
  }

  const getStartDate = (task: RobotReadMissionJobInline) => {
    let startDate: Date
    if (task.scheduler_settings && task.scheduler_settings.datetime) {
      startDate = new Date(task.scheduler_settings.datetime.replace(" ", "T"));
    } else{
      startDate = new Date(task.created_at.replace(" ", "T"));
      const validDateString = task.created_at.slice(0, 16); // Remove extra info
      startDate = new Date(validDateString);
    }
    return startDate;
  }
  const getSchedule = (task: RobotReadMissionJobInline) => {
    let schedule = '';
    const days = task.scheduler_settings?.weekdays;
    if (days) {
      if (days.SU === 'true') schedule += 'SU ';
      if (days.MO === 'true') schedule += 'MO ';
      if (days.TU === 'true') schedule += 'TU ';
      if (days.WE === 'true') schedule += 'WE ';
      if (days.TH === 'true') schedule += 'TH ';
      if (days.FR === 'true') schedule += 'FR ';
      if (days.SA === 'true') schedule += 'SA '  ;
    }
    return schedule;
  }

  const getAdditionalInfo = (task: RobotReadMissionJobInline) => {
    let days = '';
    if (task.scheduler_settings)
      for (const day of task.scheduler_settings.except_days)
        days += day + ' ';

    if (days === '') 
      return '';
    
    return 'Except days: ' + days;
  }

  const handleTaskClick = (task: RobotReadMissionJobInline) => {
    if (robot.robotInfo && 
        robot.robotInfo.robotRaw &&
        task.job_type !== MissionJobTypeEnum.DELETE_SCENARIO) {
      setSelectedTask({
        mission_id : task.mission_id, 
        id: task.id, job_type: task.job_type, 
        name: task.name, scheduler_settings: task.scheduler_settings, 
        robot_id: robot.robotInfo.robotRaw.id,
        user_id: robot.robotInfo.robotRaw.user_id,
        created_at: task.created_at,
        updated_at: task.updated_at,
        mission_status_last: task.mission_status_last});
      setIsModalOpen(true)
    }
  }

  const resetData = () => {
    setSelectedTask(null)
    setIsModalOpen(false)
  }
  const onCloseModal = () => {
    setIsModalOpen(true)
    resetData()
  }

  const onCancelClicked = () => {
    onCloseModal()
  }

  const onSaveClicked = () => {
    onCloseModal()
  }

  return (
    <div className={style.root}>
      <div className={style.table}>
        <table>
          <thead>
            <tr className={style.tableRow}>
              <th></th>
              <th>Task ID</th>
              <th>Name</th>
              <th>Mission ID</th>
              <th>Date</th>
              <th>Schedule</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {[...tasks]
              .sort((a: RobotReadMissionJobInline, b: RobotReadMissionJobInline) => b.id - a.id)
              .map(task => (
              <tr key={task.id} className={style.tableRow}>
                <td title={task.job_type === MissionJobTypeEnum.DELETE_SCENARIO ? 'The task will be deleted when the robot connects to the network' : ''}>
                  {task.job_type === MissionJobTypeEnum.DELETE_SCENARIO ? <RemoveSvg /> : ''}
                </td>
                <td onClick={() => handleTaskClick(task)} style={{ cursor: 'pointer', color: 'blue' }}>
                  {task.id}
                </td>
                <td>{task.name}</td>
                <td>
                  <a className={style.link} href={`${PATH.MISSION}${task.mission_id}`} target="_blank" rel="noreferrer">
                  {task.mission_id}
                  </a>
                </td>
                <td>{getStartDate(task).toLocaleString()}</td>
                <td title={getAdditionalInfo(task)}>{getSchedule(task)}</td>
                <td>{getLoadingStatus(task)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {(
          <TaskSettingsModal
            isOpen={isModalOpen}
            onClose={onCancelClicked}
            taskName={selectedTask?.name ? selectedTask.name : ''} 
            taskToEdit= {selectedTask ? selectedTask : undefined}
            onSubmit={onSaveClicked}
          />
        )}
      </div>
    </div>
  )
}

export default RobotTasks
