import { Scheduler } from '@aldabil/react-scheduler';
import {
  CellRenderedProps,
  ProcessedEvent,
  SchedulerProps,
} from '@aldabil/react-scheduler/types';
import { DayProps } from '@aldabil/react-scheduler/views/Day';
import { MonthProps } from '@aldabil/react-scheduler/views/Month';
import { WeekProps } from '@aldabil/react-scheduler/views/Week';
import { Button, Theme, useTheme  } from '@mui/material';
import React from 'react';
import { useCallback, useState } from 'react'
import { Button as CustomButton } from 'components/Button/Button'
import style from 'components/Button/Button.module.css'
import schedulerStyle from './SchedulerSettings.module.css'
import Title from 'components/Title/Title'
import TaskSettingsModal from './TaskSettingsModal/TaskSettingsModal'
import SingleTaskEditingModal from './TaskSettingsModal/SingleTaskEditingModal'
import { MainService, MissionJobTypeEnum, RobotRead, MissionJobRead, EventStatistics } from 'api/schema';
import {
  getColorById,
  scheduleToEvents,
  toISOStringYYYYMMDD,
  updateTaskDisplayInfo,
} from './TaskSchedulerUtils';

import { ConfirmationDialogAllSingle } from './ConfirmationDialogAllSingle';
import { EventEditDeletePopup } from './TaskSchedulerEventEditDeletePopup';
import { PATH } from 'utils/constants'
import { RobotApi } from 'api/RobotApi';

enum EventScopes {
  ALL = 'all',
  CURRENT = 'current',
}

const disablingCellsWithoutEvents = (
  events: ProcessedEvent[],
  { start, ...props }: CellRenderedProps,
  theme: Theme,
): React.ReactElement => {
  const filteredEvents = events.filter((event) => start.getTime() !== event.start.getTime());
  const disabled = filteredEvents.length > 0 || events.length === 0;
  const restProps = disabled ? {} : props;
  return (
    <Button
      style={{
        height: '100%',
        background: disabled ? theme.palette.action.disabled : 'transparent',
        cursor: disabled ? 'default' : 'pointer',
      }}
      disableRipple={disabled}
      {...restProps}
    />
  );
};


const TaskScheduler = () => {
  const [tasks, setTasks] = React.useState<MissionJobRead[]>([])
  const [robots, setRobots] = useState<RobotRead[]>([])
  const [eventScope, setEventScope] = React.useState<string>(EventScopes.CURRENT);
  const [refreshTaskScheduleCount, setRefreshTaskScheduleCount] = React.useState(0);
  const [calendarEvents, setCalendarEvents] = React.useState<ProcessedEvent[]>([]);
  const [eventStatistics, setEventStatistics] = React.useState<EventStatistics[]>([]);

  type ViewEvent = {
    start: Date;
    end: Date;
    view: 'day' | 'week' | 'month';
  };
  const [currentView, setCurrentView] = React.useState<ViewEvent>({
    start: new Date(),
    end: new Date(),
    view: 'month',
  });
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isSingleTaskEditingOpen, setSingleTaskEditingOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<ProcessedEvent | null>(null);
  const [openDeleteScheduleDialog, setOpenDeleteScheduleDialog] = React.useState(false);

  const getTaskById = (taskId: number): MissionJobRead | undefined => {
    return tasks.find((task: MissionJobRead) => task.id === taskId);
  };

  const isAllValueDisabled = !(selectedEvent && getTaskById(Number(selectedEvent.event_id))?.scheduler_settings?.weekdays);
  const onSettingsCloseClicked = useCallback((refresh: boolean) => {
    setIsSettingsOpen(false)
    setSelectedEvent(null)
  }, [setIsSettingsOpen, setSelectedEvent])

  const onSettingsSubmitClicked = useCallback((refresh: boolean) => {
    setIsSettingsOpen(false)
    setSelectedEvent(null)
    if (refresh) {
      setRefreshTaskScheduleCount((prevKey) => prevKey + 1);  // Increment refreshKey to trigger re-render
    }
  }, [setIsSettingsOpen, setSelectedEvent])

  const onSingleTaskEditingCloseClicked = useCallback((refresh: boolean) => {
    setSingleTaskEditingOpen(false)
    setSelectedEvent(null)
  }, [setIsSettingsOpen, setSelectedEvent])

  const onSingleTaskEditingSubmitClicked = useCallback((refresh: boolean) => {
    setSingleTaskEditingOpen(false)
    setSelectedEvent(null)
    if (refresh) {
      setRefreshTaskScheduleCount((prevKey) => prevKey + 1);  // Increment refreshKey to trigger re-render
    }
  }, [setIsSettingsOpen, setSelectedEvent])

  const [eventsLoaded, setEventsLoaded] = useState(false);
 
  const getRemoteEvents = React.useCallback<NonNullable<SchedulerProps['getRemoteEvents']>>(
    async (params) => {
      setCurrentView(params);
      let tasksSet: MissionJobRead[] = [];
      let robotSet: RobotRead[] = [];
      let eventStatisticsSet: EventStatistics[] = [];
      try {
          const responseTasks = await MainService.mainMissionJobList()
          setTasks(responseTasks.results ?? []);
          tasksSet = responseTasks.results ?? [];
          const responseRobots = await MainService.mainRobotList()
          setRobots(responseRobots.results ?? []);
          robotSet = responseRobots.results ?? [];
          const responseEventStatistics = await MainService.getEventStatisticsByDateRange(
            params.start.toISOString().split('.')[0],
            params.end.toISOString().split('.')[0]
          );
          setEventStatistics(responseEventStatistics.results ?? []);
          eventStatisticsSet = responseEventStatistics.results ?? [];
          //console.log("eventStatisticsSet:", eventStatisticsSet);
      } catch (error) {
      }
      const eventsSet: ProcessedEvent[] = [];
      try {
        for (const robot of robotSet) {
          for (const task of robot.missions_jobs) {
            if (task.job_type === MissionJobTypeEnum.DELETE_SCENARIO)
              continue;
            let loadingStatus = '';
            if (task.status) {
              loadingStatus = task.status === 'new' || task.status === 'scenario_error' ? 'Not loaded' : task.status === 'scenario_done' ? 'Loaded' : 'Loading';
            }
            const events = scheduleToEvents(
              params.start,
              params.end,
              task,
              () => getColorById(robot.id),
              loadingStatus + '\n' + 
              'Mission ID: ' + task.mission_id.toString() + '\n' + 
              'Mission name: ' + task.mission_name + '\n' ,
              robot.name ? robot.name : robot.serial_number.toString()
            );
            eventsSet.push(...events);
          }
        }

        // update event title and description for past events
        // note: event.event_id is the id of the task
        const nowDate = new Date();
        if (toISOStringYYYYMMDD(params.start) <= toISOStringYYYYMMDD(nowDate)) {
          eventsSet.forEach(event => {
            if (toISOStringYYYYMMDD(event.start) <= toISOStringYYYYMMDD(nowDate)) {
              updateTaskDisplayInfo(event, eventStatisticsSet);
            }
          });
        }
        setCalendarEvents(eventsSet);
        setEventsLoaded(true);
        // console.log("eventsSet:", eventsSet);
        return eventsSet;
      } catch (error) {
        console.error('Failed to generate events', error)
        return []
      }
    },
    [refreshTaskScheduleCount, eventsLoaded],
  );

  const handleSubmitDeleteSchedule: React.MouseEventHandler = async () => {
    try {

      if (!selectedEvent) {
        throw new Error(`The selected event is null`);
      }
      const foundTask = getTaskById(Number(selectedEvent.event_id));
      if (eventScope === EventScopes.CURRENT && foundTask?.scheduler_settings?.weekdays) {

        if (foundTask && foundTask.scheduler_settings) {
          console.debug(`Deleting task with id ${selectedEvent.event_id} and event date ${selectedEvent.start}`);
          let new_scheduler_settings = { 
            ...foundTask.scheduler_settings, 
            except_days: [...foundTask.scheduler_settings.except_days]
          };
          new_scheduler_settings.except_days.push(toISOStringYYYYMMDD(selectedEvent.start));
          new_scheduler_settings.except_days.sort();
          // await store.dispatch(robotThunks.updateJobAndUploadJobToRobot(Number(selectedEvent.event_id), 
          await RobotApi.updateTheEntireJob(Number(selectedEvent.event_id), 
            foundTask.mission_id,
            foundTask.robot_id,
            new_scheduler_settings,
            foundTask.name ? foundTask.name : 'Cleaning Task',
          )

        }
        else {
          console.log("Task not found: ", selectedEvent)
        }

      } else {
        if (foundTask && foundTask?.robot_id !== foundTask.id){
          // await store.dispatch(robotThunks.updateJobTypeAndUploadJobToRobot(foundTask.id, MissionJobTypeEnum.DELETE_SCENARIO));
          await RobotApi.updateMissionJobType(foundTask.id, MissionJobTypeEnum.DELETE_SCENARIO);
        }
        console.debug(`Deleting schedule with id ${selectedEvent.event_id}`);
      }
      setOpenDeleteScheduleDialog(false)
    } catch (e) {
      console.error(`Failed to delete scheduled task: ${e}`);
    }
    setRefreshTaskScheduleCount((oldValue) => ++oldValue);
    setEventScope(EventScopes.CURRENT)
  };

  const theme = useTheme();

  const defaultDaySettings: DayProps = {
    startHour: 0,
    endHour: 23,
    step: 60,
    cellRenderer: ({ start, ...props }: CellRenderedProps) =>
      disablingCellsWithoutEvents(calendarEvents, { start, ...props }, theme),
  };
  const defaultWeekSettings: WeekProps = {
    weekDays: [0, 1, 2, 3, 4, 5, 6],
    weekStartOn: 1,
    startHour: 0,
    endHour: 23,
    step: 60,
    cellRenderer: ({ start, ...props }: CellRenderedProps) =>
      disablingCellsWithoutEvents(calendarEvents, { start, ...props }, theme),
  };
  const defaultMonthSettings: MonthProps = {
    weekDays: [0, 1, 2, 3, 4, 5, 6],
    weekStartOn: 0,
    startHour: 0,
    endHour: 23,
    cellRenderer: ({ start, ...props }: CellRenderedProps) =>
      disablingCellsWithoutEvents(calendarEvents, { start, ...props }, theme),
  };

  return (
    <>
    <div className={schedulerStyle.mainView}>
      <div className={schedulerStyle.titleBlock}>
        <Title text={"Schedule"} />{' '}
        <CustomButton.Standart
          className={style.button}
          onClick={() => {
            setIsSettingsOpen(true)
            setSelectedEvent(null)
          }}
        >
          Add New Task
        </CustomButton.Standart>
      </div>
      <div style={{ height: '100%', width: '100%' }}>
        <Scheduler
          // react-scheduler does not support refreshing, workaround by mounting a new instance.
          key={`scheduler-${refreshTaskScheduleCount}`}
          view={currentView.view}
          day={defaultDaySettings}
          week={defaultWeekSettings}
          month={defaultMonthSettings}
          draggable={false}
          editable={true}
          deletable={true}
          events={eventsLoaded ? calendarEvents : []}
          getRemoteEvents={getRemoteEvents}
          height={650}
          onEventClick={(event: ProcessedEvent) => {
            // console.log("event: ", event);
            setSelectedEvent(event);
          }}
          onDelete={async (deletedId: number) => {
            setOpenDeleteScheduleDialog(true);
          }}
          customEditor={(scheduler) => (
            <ConfirmationDialogAllSingle
              confirmText={'Ok'}
              cancelText="Cancel"
              open={true}
              title={'Edit recurring task'}
              submitting={undefined}
              onClose={() => {
                scheduler.close();
                setEventScope(EventScopes.CURRENT);
              }}
              onSubmit={() => {
                if (eventScope === EventScopes.ALL)
                  setIsSettingsOpen(true)
                else if (eventScope === EventScopes.CURRENT)
                  setSingleTaskEditingOpen(true)
                else
                  throw new Error(`Unknown event scope: ${eventScope}`);

                scheduler.close();
                setEventScope(EventScopes.CURRENT);
              }}
            >
              <EventEditDeletePopup
                currentValue={EventScopes.CURRENT}
                allValue={EventScopes.ALL}
                value={eventScope}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setEventScope(event.target.value)
                }
                disableAll={isAllValueDisabled}
              />
            </ConfirmationDialogAllSingle>
          )}
          // TODO: Add a description Mission name
          viewerExtraComponent={(_fields, event) => {
            return (      
              <div style={{ whiteSpace: 'pre-line' }}>
                <p>{event.description}
                  <a className={schedulerStyle.link} href={`${PATH.MISSION}${Number(event.description.match(/Mission ID: (\d+)/)[1])}`} target="_blank" rel="noreferrer"> 
                    Go to mission
                  </a>
                </p>
              </div>
            );  
          }}
        />
        {(
          <TaskSettingsModal
            isOpen={isSettingsOpen}
            onClose={onSettingsCloseClicked}
            taskName={'Cleaning Task'}
            taskToEdit= {selectedEvent ? getTaskById(Number(selectedEvent.event_id)) : undefined}
            onSubmit={onSettingsSubmitClicked}
          />
        )}
        {(
          <SingleTaskEditingModal
            isOpen={isSingleTaskEditingOpen}
            onClose={onSingleTaskEditingCloseClicked}
            onSubmit={onSingleTaskEditingSubmitClicked}
            taskToEdit= {selectedEvent ? getTaskById(Number(selectedEvent.event_id)) : undefined}
            dateToEdit= {selectedEvent ? selectedEvent.start : null}
            taskName={'Cleaning Task'}
          />
        )}
        {openDeleteScheduleDialog && (
          <ConfirmationDialogAllSingle
            confirmText={'Ok'}
            cancelText="Cancel"
            open={openDeleteScheduleDialog}
            title={'Delete recurring task'}
            submitting={undefined}
            onClose={() => {
              setOpenDeleteScheduleDialog(false);
              setEventScope(EventScopes.CURRENT);
            }}
            onSubmit={handleSubmitDeleteSchedule}
          >
            <EventEditDeletePopup
              currentValue={EventScopes.CURRENT}
              allValue={EventScopes.ALL}
              value={eventScope}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEventScope(event.target.value)
              }
              disableAll={isAllValueDisabled}
            />
          </ConfirmationDialogAllSingle>
        )}
      </div>
    </div>
    </>
  );
};

export default TaskScheduler;
