import { RobotApi } from 'api/RobotApi'
import { SchedulerSettings, MissionJobTypeEnum} from 'api/schema'
import { RobotActions } from 'store/actions/robot'
import { MISSION_JOB_STATUSES, PROGRESS_TYPES, ROBOT_MODES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const createJob = (missionId: number, robotId: number) => {
  // Пока не используется, мб удалить?
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(RobotActions.setCreatingJobProgress(PROGRESS_TYPES.WORK))
      const job = await RobotApi.createJob(missionId, robotId)
      dispatch(RobotActions.setJobId(job.id))
      dispatch(RobotActions.setCreatingJobProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(RobotActions.setCreatingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error creating job')
    }
  }
}

const uploadJobToRobot = (jobId: number) => {
  // Пока не используется, мб удалить?
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.WORK))
      await RobotApi.uploadJobToRobot(jobId)
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error creating job')
    }
  }
}

const createJobAndUploadJobToRobot = (missionId: number, 
                                      robotId: number, 
                                      scheduler_settings?: SchedulerSettings,
                                      name?: string, 
                                      description?: string) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.WORK))
      const job = await RobotApi.createJob(missionId, robotId, scheduler_settings, name, description)
      dispatch(RobotActions.setJobId(job.id))
      dispatch(RobotActions.setJobStatus(MISSION_JOB_STATUSES.CALCULATING))
      await RobotApi.uploadJobToRobot(job.id)
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error creating job')
    }
  }
}


const updateJobAndUploadJobToRobot = (taskId: number,
  missionId: number, 
  robotId: number, 
  scheduler_settings?: SchedulerSettings,
  name?: string) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.WORK))
      const job = await RobotApi.updateTheEntireJob(taskId, missionId, robotId, scheduler_settings, name)
      dispatch(RobotActions.setJobId(job.id))
      dispatch(RobotActions.setJobStatus(MISSION_JOB_STATUSES.CALCULATING))
      await RobotApi.uploadJobToRobot(job.id)
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error updating job')
    }
  }
}

const updateJobTypeAndUploadJobToRobot = (taskId: number,
  job_type: MissionJobTypeEnum) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.WORK))
      const job = await RobotApi.updateMissionJobType(taskId, job_type)
      dispatch(RobotActions.setJobId(job.id))
      dispatch(RobotActions.setJobStatus(MISSION_JOB_STATUSES.CALCULATING))
      await RobotApi.uploadJobToRobot(job.id)
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error updating job')
    }
  }
}


const setRobotToAutomaticMode = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await RobotApi.switchMode(robotId, ROBOT_MODES.MODE_AUTOMATIC)
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error setting robot mode')
    }
  }
}

const setRobotToParkingMode = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await RobotApi.switchMode(robotId, ROBOT_MODES.MODE_PARKING)
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error setting robot mode')
    }
  }
}

const setRobotToChargingMode = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await RobotApi.switchMode(robotId, ROBOT_MODES.MODE_CHARGING)
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error setting robot mode')
    }
  }
}

const setRobotToWashingMode = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await RobotApi.switchMode(robotId, ROBOT_MODES.MODE_WASHING)
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error setting robot mode')
    }
  }
}
const setRobotToMappingMode = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await RobotApi.switchMode(robotId, ROBOT_MODES.MODE_MAPPING)
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error setting robot mode')
    }
  }
}
const setRobotToRouteRecordingMode = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await RobotApi.switchMode(robotId, ROBOT_MODES.MODE_ROUTE_RECORDING)
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error setting robot mode')
    }
  }
}

const setRobotNotSetMode = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await RobotApi.switchMode(robotId, ROBOT_MODES.MODE_NOT_SET)
    } catch (error) {
      dispatch(RobotActions.setUploadingJobProgress(PROGRESS_TYPES.ERROR))
      console.log('Error setting robot mode')
    }
  }
}

const fetchRobot = (robotId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(RobotActions.setLoadingProgress(PROGRESS_TYPES.WORK))
      const robotInfo = await RobotApi.fetchRobot(robotId)
      dispatch(RobotActions.setRobotInfo(robotInfo))
      dispatch(RobotActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(RobotActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

export const robotThunks = {
  createJob,
  uploadJobToRobot,
  createJobAndUploadJobToRobot,
  updateJobAndUploadJobToRobot,
  updateJobTypeAndUploadJobToRobot,
  setRobotToAutomaticMode,
  setRobotToParkingMode,
  setRobotToChargingMode,
  setRobotToWashingMode,
  setRobotToMappingMode,
  setRobotToRouteRecordingMode,
  fetchRobot,
  setRobotNotSetMode,
}
