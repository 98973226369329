import { FormControlLabel, Switch, TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import Checkbox from '@mui/material/Checkbox'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import React, { useState, useEffect } from 'react';
import style from '../TaskSettingsModal.module.css'

const weekdaysLabels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface SchedulerSettingsProps {
  initialDate: Date | null;
  initialEndDate: Date | null;
  initialWeekdays: boolean[]; 
  onSchedulerChange: (date: Date | null, endDate: Date | null, weekdays: boolean[]) => void;
}

const SchedulerSettings = ({ initialDate, initialEndDate, initialWeekdays, onSchedulerChange }: SchedulerSettingsProps) => {
    const [date, setDate] = useState<Date | null>(initialDate);
    const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
    const [weekdays, setWeekdays] = useState<boolean[]>(initialWeekdays);
    const [initialRender, setInitialRender] = useState(true)
    
    useEffect(() => {
        if (initialRender) {
          setWeekdays(initialWeekdays);
          setInitialRender(false);
        }
    }, [initialWeekdays, initialRender]);
    
    const handleDateChange = (newDate: Date | null) => {
        setDate(newDate);
        onSchedulerChange(newDate, endDate, weekdays);
        // console.log("Selected date", date);
    };
    const handlEndDateChange = (newDate: Date | null) => {
        setEndDate(newDate);
        onSchedulerChange(date, newDate, weekdays);
        // console.log("Selected endDate", endDate);
    };

    const handleWeekdayChange = (dayIndex: number) => {
        const updatedWeekdays = [...weekdays];
        updatedWeekdays[dayIndex] = !updatedWeekdays[dayIndex];
        setWeekdays(updatedWeekdays);
        onSchedulerChange(date, endDate, updatedWeekdays);
    };

    const handleClearDate = () => {
      setDate(null);
      onSchedulerChange(null,  endDate, weekdays);
    };
    
    const handleClearEndDate = () => {
      setEndDate(null);
      onSchedulerChange(date,  null, weekdays);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={style.dateTimeContainer}>
                <DateTimePicker
                    label="Choose date and time"
                    value={date}
                    onChange={handleDateChange}
                    slots={{ textField: TextField }}
                    slotProps={{
                        textField: {
                            sx: {
                                '& .MuiInputBase-root': {
                                    height: 40,
                                    width: '119%',  //TODO: Fix view
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 0,
                                },
                                '& .MuiInputLabel-root': { 
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    top: '-15%',
                                },
                                '& .MuiInputLabel-shrink': { 
                                    top: '-20%', // Move label to top when active
                                    left: '5%', // Align to the left when active
                                    transform: 'none',
                                    fontSize: '12px'
                                },
                            },
                        },
                    }}
                />
                <Button.Outlined className={style.btnClear} onClick={handleClearDate}>
                  Clear date and time
                </Button.Outlined>
            </div>
            {/* <div className={style.dateTimeContainer}>
                <DatePicker
                    label="Choose end date"
                    value={endDate}
                    onChange={handlEndDateChange}
                />
                <Button.Outlined className={style.btnClear} onClick={handleClearEndDate}>
                  Clear date 
                </Button.Outlined>
            </div> */}


            <FormControlLabel
                control={
                    <Checkbox
                        checked={weekdays.every(Boolean)}
                        onChange={() => {
                            const allChecked = weekdays.every(Boolean);
                            const newWeekdays = weekdays.map(() => !allChecked);
                            setWeekdays(newWeekdays);
                            onSchedulerChange(date, endDate, newWeekdays); // TO DO
                        }}
                    />
                }
                label="Set a cleaning schedule by day of the week"
                sx={{ paddingTop: 3 }}
            />
            {weekdays.some(Boolean) && (
                <div className={style.weekdays}>
                   {weekdays.map((dayChecked, index) => (
                       <FormControlLabel
                           key={index}
                           control={<Switch checked={dayChecked} onChange={() => handleWeekdayChange(index)} />}
                           label={weekdaysLabels[index]}
                       />
                   ))}
                </div>
            )}

        </LocalizationProvider>
    );
};

export default SchedulerSettings;

