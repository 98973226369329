import { MainApi } from 'api/MainApi'
import { Button } from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import Title from 'components/Title/Title'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'
import { MissionJobRead, MainService } from 'api/schema'

import MissionTable from './MissionTable/MissionTable'

import style from './MissionList.module.css'

const MissionList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [creatingMissionProgress, setCreatingMissionProgress] = useState(PROGRESS_TYPES.IDLE)
  const [missions, setMissions] = useState<IMissionInfo[]>([])
  const [tasks, setTasks] = useState<MissionJobRead[]>([])

  const fetchData = async () => {
    try {
      setLoadingProgress(PROGRESS_TYPES.WORK)
      const missions = await MainApi.fetchMissions()
      setMissions(missions)
      const response = await MainService.mainMissionJobList()
      setTasks(response.results ?? [])
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  useEffectOnce(() => {
    fetchData()
  })

  const createNewMission = async () => {
    try {
      setCreatingMissionProgress(PROGRESS_TYPES.WORK)
      const mission = await MainApi.createMission('New Mission', '')
      setCreatingMissionProgress(PROGRESS_TYPES.SUCCESS)
      navigate(`${PATH.MISSION}${mission.id}`)
    } catch (error) {
      setCreatingMissionProgress(PROGRESS_TYPES.ERROR)
      console.log('Error creating mission: ', error)
    }
  }

  return (
    <>
      {loadingProgress === PROGRESS_TYPES.WORK && <Loader />}
      {loadingProgress === PROGRESS_TYPES.ERROR && <div>Error</div>}
      {loadingProgress === PROGRESS_TYPES.SUCCESS && (
        <div className={style.missions}>
          <div className={style.titleBlock}>
            <Title text={t('missions.title')} />{' '}
            <Button.Standart onClick={createNewMission} loading={creatingMissionProgress === PROGRESS_TYPES.WORK}>
              Create New Mission
            </Button.Standart>
          </div>
          <MissionTable missions={missions.sort((a,b)=> b.id - a.id)} tasks={tasks} onMissionsUpdated={fetchData} />
        </div>
      )}
    </>
  )
}

export default MissionList
