import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './SchedulerSettings.module.css'
import { Button } from 'components/Button/Button'

import { Loading } from './Loading';

const dialogClasses = {
  title: 'confirmation-dialogue-info-value',
  actionBtn: 'confirmation-dialogue-action-button',
};
const StyledDialog = styled((props: DialogProps) => <Dialog {...props} />)(() => ({
  [`& .${dialogClasses.title}`]: {
    flex: '1 1 auto',
  },
  [`& .${dialogClasses.actionBtn}`]: {
    minWidth: 80,
  },
}));

export interface ConfirmationDialogProps extends DialogProps {
  title?: string;
  confirmText?: string;
  cancelText?: string;
  // disable the dialog actions and shows a loading indicator
  submitting?: boolean;
  classes?: DialogActionsProps['classes'] & { button: string };
  toolbar?: React.ReactNode;
  onSubmit?: React.FormEventHandler;
}

export function ConfirmationDialogAllSingle({
  title = 'Confirm',
  confirmText = 'OK',
  cancelText = 'Cancel',
  submitting = false,
  classes,
  onSubmit,
  toolbar,
  onClose,
  children,
  ...otherProps
}: ConfirmationDialogProps): JSX.Element {
  return (
    <StyledDialog
      onClose={onClose}
      {...otherProps}
      PaperProps={{
        sx: {
          maxWidth: '600px',
          width: '100%',
          height: '300px',
          borderRadius: '0px'
        },
      }}
    >
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit && onSubmit(ev);
        }}
        className={style.content}
        aria-label={title}
      >
        <DialogTitle>
          <Grid container wrap="nowrap">
            <Grid item className={style.head}>
              {title}
            </Grid>
            <Grid item>{toolbar}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions className={style.footer}>
          <Button.Outlined
            onClick={(ev) => onClose && onClose(ev, 'escapeKeyDown')}
            disabled={submitting}
            className={clsx(dialogClasses.actionBtn, style.btn)}
            size="medium"
          >
            {cancelText}
          </Button.Outlined>
          <Button.Standart
            type="submit"
            disabled={submitting}
            className={clsx(dialogClasses.actionBtn, style.btn)}
            size="medium"
          >
            <Loading hideChildren loading={submitting} size="1.5em" color="inherit">
              {confirmText}
            </Loading>
          </Button.Standart>
        </DialogActions>
      </form>
    </StyledDialog>
  );
}
