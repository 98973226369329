import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { MainApi } from 'api/MainApi'
import { Feature, GeoJsonProperties, Geometry, Point, Polygon } from 'geojson'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { MissionActions } from 'store/actions/mission'
import {
  MISSION_CALCULATING_STATUSES,
  MISSION_MAP_DRAWING_MODES,
  MISSION_PAGE_MODES,
  MISSION_ZONE_TYPES,
  PROGRESS_TYPES,
  TRANSFER_CALCULATING_STATUSES,
  TRANSFER_TYPE,
} from 'utils/constants'
import {
  ICleaningZone,
  ILaunchPoint,
  IPoint,
  ITransferRoute,
  IZone,
  IZoneTransferRoute,
  RootState,
} from 'utils/interfaces'

const fetchMission = (missionId: number) => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(MissionActions.setLoadingProgress(PROGRESS_TYPES.WORK))
      const mission = await MainApi.fetchMission(missionId)
      dispatch(MissionActions.setMission(mission))
      dispatch(MissionActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(MissionActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

const fetchCalculationStatus = (missionId: number) => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      const status = await MainApi.fetchCalculationStatus(missionId)
      if (!status) {
        return
      }
      if (status.status !== getState().mission.status) {
        const newMission = await MainApi.fetchMission(getState().mission.missionInfo?.id || 0)
        dispatch(MissionActions.setMission(newMission))
        dispatch(MissionActions.setCalculatingStatus(newMission.status))
      }
      if (status.is_ready_to_start_calculation !== getState().mission.isReadyForCalculation) {
        dispatch(MissionActions.setIsReadyForCalculation(status.is_ready_to_start_calculation))
      }

      // // Если статусы зон поменялись, то надо либо удалить маршруты, либо загрузить их
      // status.cleaning_zones_statuses.forEach(async cleaningZoneNewStatusObject => {
      //   const cleaningZone = getState().mission.cleaningZones.find(
      //     zone => zone.properties?.zone_id === cleaningZoneNewStatusObject.id
      //   )
      //   if (cleaningZone && cleaningZone.properties?.status !== cleaningZoneNewStatusObject.status) {
      //     if (cleaningZoneNewStatusObject.status === MISSION_CALCULATING_STATUSES.OK) {
      //       // Обновляем маршрут зоны
      //       const { route } = await MainApi.fetchCleaningZone(cleaningZone.properties?.zone_id)
      //       dispatch(MissionActions.addRoute(route))
      //     } else if (
      //       cleaningZoneNewStatusObject.status === MISSION_CALCULATING_STATUSES.ERROR ||
      //       cleaningZone.properties?.status === MISSION_CALCULATING_STATUSES.NEW ||
      //       cleaningZone.properties?.status === MISSION_CALCULATING_STATUSES.CALCULATING
      //     ) {
      //       // Удаляем маршрут зоны
      //       dispatch(MissionActions.deleteRoute(cleaningZone.properties?.zone_id))
      //     }
      //   }
      // })
      dispatch(MissionActions.setZonesStatus(status.cleaning_zones_statuses))
      dispatch(MissionActions.setLayerStatus(status.xyz_layers_statuses))

      return status
    } catch (error) {
      console.log(error)
    }
  }
}

const createCleaningZone = (
  zoneName: string,
  zoneDescription: string,
  zoneGeometry: Polygon,
  launchPointGeometry: Point | undefined
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.WORK))
      const { zone, launchPoint } = await MainApi.createCleaningZone(
        getState().mission.missionInfo?.id || -1,
        zoneName,
        zoneDescription,
        zoneGeometry,
        launchPointGeometry
      )
      dispatch(MissionActions.setCleaningZones([...getState().mission.cleaningZones, zone as ICleaningZone]))
      dispatch(MissionActions.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW))
      dispatch(MissionActions.setTempZone(undefined))
      dispatch(MissionActions.setTempLaunchPoint(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
      if (launchPoint) {
        dispatch(MissionActions.addLaunchPoint(launchPoint))
      }
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      console.log('Error creating cleaning zone')
      dispatch(MissionActions.setTempZone(undefined))
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

const createRestrictZone = (zoneName: string, zoneDescription: string, zoneGeometry: Polygon) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.WORK))
      const zone = await MainApi.createRestrictZone(
        getState().mission.missionInfo?.id || -1,
        zoneName,
        zoneDescription,
        zoneGeometry
      )
      dispatch(MissionActions.setRestrictZones([...getState().mission.restrictZones, zone]))
      dispatch(MissionActions.deleteRoutes())
      dispatch(MissionActions.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW))
      dispatch(MissionActions.setTempZone(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      console.log('Error creating restrict zone')
      dispatch(MissionActions.setTempZone(undefined))
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

const editCleaningZone = (
  zoneId: number,
  zoneName: string,
  zoneDescription: string,
  zoneGeometry: Polygon,
  launchPointGeometry: Point | undefined
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const geometryChanged = !isEqual(zoneGeometry, getState().mission.editingZone?.geometry)
      const launchPointChanged = !isEqual(launchPointGeometry, getState().mission.editingLaunchPoint?.geometry)
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.WORK))
      const { zone, launchPoint } = await MainApi.patchCleaningZone(
        zoneId,
        getState().mission.missionInfo?.id || -1,
        zoneName,
        zoneDescription,
        geometryChanged ? zoneGeometry : undefined,
        launchPointChanged ? (launchPointGeometry ? launchPointGeometry : null) : undefined
      )
      if (geometryChanged || launchPointChanged) {
        dispatch(MissionActions.deleteRoute(zone.properties?.zone_id as number))
      }
      dispatch(MissionActions.editCleaningZone(zone as ICleaningZone))
      dispatch(MissionActions.deleteLaunchPoint(zoneId))
      if (launchPoint) {
        dispatch(MissionActions.addLaunchPoint(launchPoint))
      }
      dispatch(MissionActions.setEditingZone(undefined))
      dispatch(MissionActions.setTempZone(undefined))
      dispatch(MissionActions.setTempLaunchPoint(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      console.log('Error editing cleaning zone')
      dispatch(MissionActions.setEditingZone(undefined))
      dispatch(MissionActions.setSavingZoneProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

const editRestrictZone = (zoneId: number, zoneName: string, zoneDescription: string, zoneGeometry: Polygon) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const geometryChanged = !isEqual(zoneGeometry, getState().mission.editingZone?.geometry)
      const zone = await MainApi.patchRestrictZone(
        zoneId,
        getState().mission.missionInfo?.id || -1,
        zoneName,
        zoneDescription,
        geometryChanged ? zoneGeometry : undefined
      )
      dispatch(MissionActions.editRestrictZone(zone))
      dispatch(MissionActions.deleteRoutes())
      dispatch(MissionActions.setEditingZone(undefined))
      dispatch(MissionActions.setTempZone(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    } catch (error) {
      console.log('Error editing restrict zone')
      dispatch(MissionActions.setEditingZone(undefined))
    }
  }
}

const deleteCleaningZone = (zone: IZone) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await MainApi.deleteCleaningZone(zone.properties?.zone_id as number)
      const newZones = getState().mission.cleaningZones.filter(zone_ => zone_.id !== zone.id)
      dispatch(MissionActions.setCleaningZones(newZones))
      dispatch(MissionActions.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW))
      dispatch(MissionActions.deleteRoute(zone.properties?.zone_id))
      dispatch(MissionActions.deleteLaunchPoint(zone.properties?.zone_id))
    } catch (error) {
      console.log('Error deleting zone')
    }
  }
}

const deleteRestrictZone = (zone: IZone) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await MainApi.deleteRestrictZone(zone.properties?.zone_id as number)
      const newZones = getState().mission.restrictZones.filter(zone_ => zone_.id !== zone.id)
      dispatch(MissionActions.setRestrictZones(newZones))
      dispatch(MissionActions.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW))
      dispatch(MissionActions.deleteRoutes())
    } catch (error) {
      console.log('Error deleting zone')
    }
  }
}

const startCalculatingRoute = (zoneId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(MissionActions.setCalculatingStatus(MISSION_CALCULATING_STATUSES.CALCULATING))
      await MainApi.startCalculatingRoute(zoneId)
    } catch (error) {
      console.log('Error start calculating route')
    }
  }
}

const cancelEditingZone = () => {
  return (dispatch: any, getState: () => RootState) => {
    dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    dispatch(MissionActions.setTempZone(undefined))
    dispatch(MissionActions.setEditingZone(undefined))
    dispatch(MissionActions.setTempLaunchPoint(undefined))
    dispatch(MissionActions.setEditingLaunchPoint(undefined))
  }
}

const createFeature = (evt: { features: Feature[] }) => {
  return async (dispatch: any, getState: () => RootState) => {
    const mapMode = getState().mission.mapMode
    if (mapMode === MISSION_MAP_DRAWING_MODES.DRAW_POLYGON && evt.features[0]) {
      ;(evt.features[0] as Feature<Geometry, GeoJsonProperties>).properties = { name: 'New zone' }
      dispatch(MissionActions.setTempZone(evt.features[0] as IZone))
      dispatch(MissionActions.setTempLaunchPoint(undefined))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE))
    } else if (mapMode === MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT && evt.features[0]) {
      dispatch(MissionActions.setTempLaunchPoint(evt.features[0] as ILaunchPoint))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE))
    } else if (mapMode === MISSION_MAP_DRAWING_MODES.ADD_MISSION_START_POINT && evt.features[0]) {
      try {
        const missionId = getState().mission.missionInfo?.id
        if (!missionId) {
          return
        }
        const startPoint = await MainApi.changeMissionStartPoint(missionId, (evt.features[0] as IPoint).geometry)
        if (startPoint) {
          dispatch(MissionActions.setMissionStartPoint(startPoint))
        }
        dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
      } catch (error) {
        console.log('Error creating start point')
      }
    } else if (mapMode === MISSION_MAP_DRAWING_MODES.ADD_MISSION_FINISH_POINT && evt.features[0]) {
      try {
        const missionId = getState().mission.missionInfo?.id
        if (!missionId) {
          return
        }
        const finishPoint = await MainApi.changeMissionFinishPoint(missionId, (evt.features[0] as IPoint).geometry)
        if (finishPoint) {
          dispatch(MissionActions.setMissionFinishPoint(finishPoint))
        }
        dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
      } catch (error) {
        console.log('Error creating finish point')
      }
    }
  }
}

const editFeature = (evt: { features: Feature[] }) => {
  return async (dispatch: any, getState: () => RootState) => {
    const mode = getState().mission.mapMode
    if (mode === MISSION_MAP_DRAWING_MODES.DRAW_POLYGON && evt.features[0]) {
      dispatch(MissionActions.setTempZone(evt.features[0] as IZone))
    } else if (mode === MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT && evt.features[0]) {
      dispatch(MissionActions.setTempLaunchPoint(evt.features[0] as ILaunchPoint))
    } else if (mode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE) {
      dispatch(MissionActions.setTempZone(evt.features[0] as IZone))
    } else if (mode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_TRANSFER_ROUTE) {
      dispatch(MissionActions.setTempTransfer(evt.features[0] as IZoneTransferRoute))
    } else if (mode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_MISSION_POINT) {
      dispatch(MissionActions.setMissionTempPoint(evt.features[0] as IPoint))
    } else if (mode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE_LAUNCH_POINT) {
      dispatch(MissionActions.setTempLaunchPoint(evt.features[0] as ILaunchPoint))
    }
  }
}

const setEditingZone = (zone: IZone) => {
  return (dispatch: any, getState: () => RootState) => {
    dispatch(MissionActions.setEditingZone(zone))
    dispatch(MissionActions.setTempZone(cloneDeep(zone)))
    dispatch(MissionActions.setHighlightedFeature(zone))
    dispatch(MissionActions.setMode(MISSION_PAGE_MODES.EDIT_ZONE))
    if (zone.properties.zone_type === MISSION_ZONE_TYPES.CLEANING) {
      const launchPoint = getState().mission.launchPoints.find(
        launchPoint => launchPoint.properties?.zone_id === zone.properties.zone_id
      )
      dispatch(MissionActions.setEditingLaunchPoint(launchPoint))
      dispatch(MissionActions.setTempLaunchPoint(launchPoint))
    }
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE))
  }
}

const setEditingTransfer = (route: ITransferRoute) => {
  return (dispatch: any, getState: () => RootState) => {
    dispatch(MissionActions.setEditingTransfer(route))
    const userGeometryTransfer = cloneDeep(route)
    const tempGeometry = userGeometryTransfer.geometry
    userGeometryTransfer.geometry = cloneDeep(userGeometryTransfer.userGeometry)
    userGeometryTransfer.userGeometry = tempGeometry
    dispatch(MissionActions.setTempTransfer(userGeometryTransfer))
    dispatch(MissionActions.setHighlightedFeature(route))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_TRANSFER_ROUTE))
  }
}

const cancelEditingTransfer = () => {
  return (dispatch: any, getState: () => RootState) => {
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    dispatch(MissionActions.setEditingTransfer(undefined))
    dispatch(MissionActions.setTempTransfer(undefined))
  }
}

const editTransfer = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const transferId = getState().mission.tempTransfer?.properties.id
      if (transferId) {
        await MainApi.patchTransferRoute(transferId, getState().mission.tempTransfer?.geometry)
      }
      const tempTransfer = cloneDeep(getState().mission.tempTransfer as IZoneTransferRoute)
      if (tempTransfer) {
        tempTransfer.userGeometry = tempTransfer.geometry
        // tempTransfer.geometry = { type: 'LineString', coordinates: [] }
        tempTransfer.properties.status = TRANSFER_CALCULATING_STATUSES.NEW
        dispatch(MissionActions.editTransferRoute(tempTransfer))
      }
      dispatch(MissionActions.setEditingTransfer(undefined))
      dispatch(MissionActions.setTempTransfer(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    } catch (error) {
      console.log('Error editing cleaning zone', error)
      dispatch(MissionActions.setEditingTransfer(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    }
  }
}

const deleteTransfer = (transferId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await MainApi.deleteTransferRoute(transferId)
      const newTransferRoutes = getState().mission.transferRoutes.filter(route_ => route_.properties.id !== transferId)
      dispatch(MissionActions.setTransferRoutes(newTransferRoutes))
    } catch (error) {
      console.log('Error deleting transfer')
    }
  }
}

const deleteStartPoint = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const missionId = getState().mission.missionInfo?.id || 0
      await MainApi.changeMissionStartPoint(missionId, null)
      dispatch(MissionActions.setMissionStartPoint(undefined))
    } catch (error) {
      console.log('Error deleting start point')
    }
  }
}

const deleteFinishPoint = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const missionId = getState().mission.missionInfo?.id || 0
      await MainApi.changeMissionFinishPoint(missionId, null)
      dispatch(MissionActions.setMissionFinishPoint(undefined))
    } catch (error) {
      console.log('Error deleting finish point')
    }
  }
}

const setEditingPoint = (point: IPoint) => {
  return (dispatch: any, getState: () => RootState) => {
    dispatch(MissionActions.setMissionEditingPoint(point))
    dispatch(MissionActions.setMissionTempPoint(cloneDeep(point)))
    dispatch(MissionActions.setHighlightedFeature(point))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_MISSION_POINT))
  }
}

const cancelEditingPoint = () => {
  return (dispatch: any, getState: () => RootState) => {
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    dispatch(MissionActions.setMissionEditingPoint(undefined))
    dispatch(MissionActions.setMissionTempPoint(undefined))
  }
}

const editPoint = (type: TRANSFER_TYPE, point: IPoint | undefined) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      if (!point) {
        return
      }
      const missionId = getState().mission.missionInfo?.id || 0
      if (type === TRANSFER_TYPE.START) {
        const startPoint = await MainApi.changeMissionStartPoint(missionId, point.geometry)
        dispatch(MissionActions.setMissionStartPoint(startPoint))
      } else {
        const finishPoint = await MainApi.changeMissionFinishPoint(missionId, point.geometry)
        dispatch(MissionActions.setMissionFinishPoint(finishPoint))
      }
    } catch (error) {
      console.log('Error editing cleaning zone')
    }
    dispatch(MissionActions.setMissionTempPoint(undefined))
    dispatch(MissionActions.setMissionEditingPoint(undefined))
    dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
  }
}

export const missionThunks = {
  fetchMission,
  fetchCalculationStatus,
  createCleaningZone,
  editCleaningZone,
  deleteCleaningZone,
  createRestrictZone,
  editRestrictZone,
  deleteRestrictZone,
  startCalculatingRoute,
  cancelEditingZone,
  createFeature,
  editFeature,
  setEditingZone,
  setEditingTransfer,
  cancelEditingTransfer,
  editTransfer,
  deleteTransfer,
  deleteStartPoint,
  deleteFinishPoint,
  setEditingPoint,
  cancelEditingPoint,
  editPoint,
}
