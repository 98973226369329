/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MissionJobTypeEnum {
        CLEANING = 0,
        ADDITIONAL_CLEANING = 1,
        TRANSPORT_WAYPOINTS = 2,
        TRANSPORT = 3,
        BUILD_TILES = 4,
        SCENARIO = 5,
        DELETE_SCENARIO = 255
    }