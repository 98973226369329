import RobotImage from '../RobotImage/RobotImage'
import { Button } from 'components/Button/Button'
import { useSelector } from 'react-redux'
import { selectRobot} from 'store/selectors/robot'
import { selectRobotActiveMode } from 'store/selectors/robot'
import { ROBOT_MODES } from 'utils/constants'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'

import style from './Mapping.module.css'

const Mapping = () => {
  const activeMode = useSelector(selectRobotActiveMode)
  const robot = useSelector(selectRobot)
  const missionInProgress = activeMode !== ROBOT_MODES.MODE_NOT_SET
  const missionInMapping = activeMode == ROBOT_MODES.MODE_MAPPING
  const missionInRouteRecording = activeMode == ROBOT_MODES.MODE_ROUTE_RECORDING
  
  const onSetMappingModeClicked = () => {
    if (robot.robotInfo?.id !== undefined) {
      if (!missionInMapping)
        store.dispatch(robotThunks.setRobotToMappingMode(robot.robotInfo?.id))
      else
        store.dispatch(robotThunks.setRobotNotSetMode(robot.robotInfo?.id))
    }
  }

  const onSetRouteRecordingModeClicked = () => {
    if (robot.robotInfo?.id !== undefined) {
      if (!missionInRouteRecording)
        store.dispatch(robotThunks.setRobotToRouteRecordingMode(robot.robotInfo?.id))
      else
        store.dispatch(robotThunks.setRobotNotSetMode(robot.robotInfo?.id))
    }
  }

  return (
    <div className={style.root}>
      {/* <RobotImage /> */}
      <div className={style.main}>
        <Button.Standart
          className={style.button}
          disabled={missionInProgress && !missionInMapping}
          onClick={onSetMappingModeClicked}
        >
          {!missionInMapping ? 'Start Mapping' : 'Stop Mapping'}
        </Button.Standart>
        <Button.Standart
          className={style.button}
          disabled={missionInProgress && !missionInRouteRecording}
          onClick={onSetRouteRecordingModeClicked}
        >
          {!missionInRouteRecording ? 'Start Route Recording' : 'Stop Route Recording'}
        </Button.Standart>
      </div>
      <div className={style.textLeft}>
        <div className={style.textAlignLeft}>
          {missionInMapping 
          ? 'Mapping in progress...' 
          : (missionInRouteRecording ? 'Route recording in progress...' : '')}
        </div>
      </div>
    </div>
  )
}

export default Mapping
