import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import {
  addHours,
} from 'date-fns';

import { SchedulerSettings, RobotReadMissionJobInline, EventStatistics } from 'api/schema';

/** 
 * Generates a list of ProcessedEvents to occur within the query start and end,
 * based on the provided schedule.
 * @param start The start of the query, which is generally 00:00:00 of the first
 * day in the calendar view.
 * @param end The end of the query, which is generally 23:59:59 of the last day
 * in the calendar view.
 * @param task The current scheduled task, to be checked if there are any events
 * between start and end.
 * @param description Task description.
 * @param robot_name Robot name.
 * @returns List of ProcessedEvents to occur within the query start and end.
 */
export const scheduleToEvents = (
  start: Date,
  end: Date,
  task: RobotReadMissionJobInline,
  getEventColor: () => string | undefined,
  description: string,
  robot_name: string,
): ProcessedEvent[] => {
  if (!task) {
    console.warn('Task is null!');
    return [];
  }
  let eventDate: Date
  if (task.scheduler_settings && task.scheduler_settings.datetime) {
    eventDate = new Date(task.scheduler_settings.datetime.replace(" ", "T"));
  } else{
    eventDate = new Date(task.created_at.replace(" ", "T"));
    const validDateString = task.created_at.slice(0, 16); // Remove extra info
    eventDate = new Date(validDateString);
  }

  if (!task.scheduler_settings?.weekdays &&
      eventDate < start ||
      eventDate > end)  {
    return [];
  }
  const events: ProcessedEvent[] = [];
  const duration = 3;
  const dayDuration = 24 * 60 * 60 * 1000; 
  const weekDuration = 7 * dayDuration; 
  const defaultTaskName = 'Task';
  const taskName =  "Robot " + robot_name +" | Task " + task?.id + " | " + (task?.name || defaultTaskName);
  if (task.scheduler_settings?.weekdays) {
    let currentDate = new Date(Math.max(start.getTime(), eventDate.getTime()));
    const daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] as (keyof SchedulerSettings['weekdays'])[];
    for (const dayOfWeek of daysOfWeek) {
      if (task.scheduler_settings.weekdays[dayOfWeek] === 'true') {
        let dayOffset = daysOfWeek.indexOf(dayOfWeek) - currentDate.getDay(); 
        // Ensure dayOffset is always positive
        if (dayOffset < 0) {
          dayOffset += 7;
        }
        let startDate = new Date(currentDate.valueOf() + dayOffset * dayDuration);
        startDate.setHours(eventDate.getHours(), eventDate.getMinutes());
        events.push(...addEvents(startDate, 
                                 end, 
                                 weekDuration, 
                                 task.id, 
                                 taskName, 
                                 getEventColor, 
                                 duration, 
                                 task.scheduler_settings.except_days,
                                 description));
      }
    }
  } else if (eventDate >= start && eventDate <= end) {
      events.push({
        start: eventDate,
        end: addHours(eventDate, duration),
        event_id: task.id,
        title: taskName,
        color: getEventColor(),
        duration: duration,
        description: description,
      });
  }

  return events;
};

export const addEvents = (
  start: Date,
  end: Date,
  period: number,
  id: number,
  title: string,
  getEventColor: () => string | undefined,
  duration: number,
  except_days: string[] = [],
  description: string,
): ProcessedEvent[] => {
  let events: ProcessedEvent[] = [];

  while (start <= end) {
    if (except_days.includes(toISOStringYYYYMMDD(start))){
      start = new Date(start.valueOf() + period);
      continue;
    }
    events.push({
      start: start,
      end: addHours(start, duration),
      event_id: id,
      title: title,
      color: getEventColor(),
      description: description,
    });
    start = new Date(start.valueOf() + period);
  }

  return events;
}

const getStatsDescription = (eventStats: EventStatistics): string => {
  if (eventStats) {
    const percent = eventStats.mission_progress.travel_distance_actual / eventStats.mission_progress.travel_distance_planned * 100;
    return `Progress: ${percent.toFixed(2)}%: ${eventStats.mission_progress.travel_distance_actual.toFixed(2)} out of ${eventStats.mission_progress.travel_distance_planned.toFixed(2)} m` + '\n';
  }
  return '';
};

const getEventStats = (task_id: number, date: Date, stats: EventStatistics[]): EventStatistics | null => {
  const eventStats = stats.find(stat => 
    stat.task_id === task_id && 
    toISOStringYYYYMMDD(new Date(stat.created_at)) === toISOStringYYYYMMDD(date)
  );
  if (eventStats) {
    return eventStats;
  }
  return null;
};

export const updateTaskDisplayInfo = (
  event: ProcessedEvent,
  events_statistics: EventStatistics[]
) => {
  const event_stats = getEventStats(Number(event.event_id), event.start, events_statistics);
  if (event_stats) {
    event.title = event_stats.mission_status === 4 ? 
      '✔ ' + event.title : event_stats.mission_status === 255 ? '？ ' + event.title :
      '⏳ ' + event.title;
    event.description += getStatsDescription(event_stats);
  } // ✔ ⏳ ✘ ？
};

const idToColorMap: Record<number, string> = {
  1: "BlueViolet",
  2: "Crimson",
  3: "DarkCyan",
  4: "DodgerBlue",
  5: "ForestGreen",
  6: "Gold",
  7: "Indigo",
  8: "LimeGreen",
  9: "MediumOrchid",
  10: "MediumPurple",
  11: "MediumSeaGreen",
  12: "NavajoWhite",
  13: "OliveDrab",
  14: "OrangeRed",
  15: "Orchid",
  16: "PaleTurquoise",
  17: "RoyalBlue",
  18: "SlateBlue",
  19: "SpringGreen",
  20: "Tomato",
};

export const getColorById = (id: number): string => {
  return idToColorMap[id] || "Gray"; // Default color
};

// Pad a number to 2 digits
function pad(n: number): string {
  return `${Math.floor(Math.abs(n))}`.padStart(2, '0');
}

// Get timezone offset in ISO format (+hh:mm or -hh:mm)
function getTimezoneOffset(date: Date): string {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
}

// Convert Date to local time zone ISO string that contains timezone information
export function toISOStringWithTimezone(date: Date): string {
  return (
    date.getFullYear()  +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    getTimezoneOffset(date)
  );
}

// Convert Date to local time zone ISO string YYYY-MM-DD
export function toISOStringYYYYMMDD(date: Date): string {
  return ('' +
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) + ''
  );
}
