import clsx from 'clsx'; 
import { format } from 'date-fns'
import style from './FilesList.module.css'; 


const FileModalItem = ({ file, selected, onSelect }: { file: any; selected: boolean; onSelect: () => void }) => {
  const timestamp = Math.floor(file.modified * 1000); // Convert to milliseconds and remove fraction
  const formattedDate = format(new Date(timestamp), 'dd.MM.yyyy HH:mm');
  return (
    <div className={selected ? clsx(style.tableItem, style.selected) : style.tableItem} onClick={onSelect}>
      <span>{file.name}</span>
      <span>{(file.size / 1024 / 1024).toFixed(2)} MB </span> 
      <span>{formattedDate}</span> 
    </div>
  );
};

const FilesList = ({ files, selectedFile, onSelect }: { files: any[]; selectedFile: any; onSelect: (file: any) => void }) => {
  if (!Array.isArray(files)) { 
    return <p>Loading files... </p>;  
  }
  const sortedFiles = files.sort((a, b) => b.modified - a.modified); // newest first
  return ( 
    <>
      <div className={style.tableHeader}>
        <span>File name</span>
        <span>Size</span>
        <span>Created at</span>
      </div>
      <div className={style.table}>
        {sortedFiles.map((file) => (
          <FileModalItem
            key={file.name} 
            file={file}
            selected={file === selectedFile}
            onSelect={() => onSelect(file)}
          />
        ))}
      </div>
    </>
  );
};

export default FilesList;
