import { MainApi } from 'api/MainApi'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import Loader from 'components/Loader/Loader'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MISSION_CALCULATING_STATUSES, PROGRESS_TYPES } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'
import Typography from '@mui/material/Typography'
import MissionsList from './MissionsList/MissionsList'

import style from './SelectMissionModal.module.css'

interface IDialogProps {
  selectedMissionId?: number
  isOpen: boolean
  onConfirmClick: (mission: IMissionInfo | undefined) => void
  onClose: () => void
}

const SelectMissionModal = ({ isOpen, onConfirmClick, onClose, selectedMissionId }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [missions, setMissions] = useState<IMissionInfo[]>([])
  const [selectedMission, setSelectedMission] = useState<IMissionInfo | undefined>(undefined)

  const fetchData = async () => {
    try {
      setLoadingProgress(PROGRESS_TYPES.WORK)
      const missions = await MainApi.fetchMissions()
      setMissions(missions.filter(mission => mission.status === MISSION_CALCULATING_STATUSES.OK))
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <ConfirmDialog
      isOpen={isOpen}
      className={style.root}
      onClose={onClose}
      onConfirmClick={() => onConfirmClick(selectedMission)}
      title={t('robot.modal_select_mission_title')}
      confirmText={t('robot.modal_select_mission_confirm')}
      confirmButtonDisabled={!selectedMission}
    >
      <div className={style.content}>
        {loadingProgress === PROGRESS_TYPES.WORK && <Loader />}
        {loadingProgress === PROGRESS_TYPES.SUCCESS && missions.length > 0 && (
          <MissionsList
            missions={missions.sort((a, b) => b.id - a.id)} // the highest first
            onSelect={mission => setSelectedMission(mission)}
            selectedMission={selectedMission}
          />
        )}
        {loadingProgress === PROGRESS_TYPES.SUCCESS && missions.length === 0 && (
          <Typography variant="body1" align="center">
            {t('robot.modal_missions_empty')}
          </Typography>
        )}
      </div>
    </ConfirmDialog>
  )
}

export default SelectMissionModal
