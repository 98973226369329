import { RobotApi } from 'api/RobotApi'
import Loader from 'components/Loader/Loader'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useState } from 'react'
import { PROGRESS_TYPES } from 'utils/constants'
import { IGarage, IRobotInfo } from 'utils/interfaces'
import { ROBOT_NETWORK_STATUSES } from 'utils/constants'

import RobotListView from './RobotListView/RobotListView'

const RobotList = () => {
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [robots, setRobots] = useState<IRobotInfo[]>([])
  const [garages, setGarages] = useState<IGarage[]>([])

  const fetchData = async () => {
    try {
      setLoadingProgress(PROGRESS_TYPES.WORK)
      const robots = await RobotApi.fetchRobots()
      setRobots(robots)
      const garages = await RobotApi.fetchGarages()
      setGarages(garages)
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  const fetchGarages = async () => {
    const garages = await RobotApi.fetchGarages()
    setGarages(garages)
  }

  useEffectOnce(() => {
    fetchData()
  })

  const stateList = {
    [`${PROGRESS_TYPES.SUCCESS}`]: (
      <RobotListView 
        robots={robots.sort((a, b) => {
          if (a.networkStatus === b.networkStatus) {
            return a.serialNumber - b.serialNumber;
          }
          return a.networkStatus === ROBOT_NETWORK_STATUSES.ONLINE ? -1 : 1;
        })}  
        garages={garages} 
        onRobotsUpdated={fetchData} 
        onGaragesChanged={fetchGarages} 
      />
    ),
    [`${PROGRESS_TYPES.WORK}`]: <Loader />,
    [`${PROGRESS_TYPES.ERROR}`]: <div>Error</div>,
  }

  return stateList[loadingProgress]
}

export default RobotList
